<section class="flex-col stretch-col">

  <div class="moduleheader flex-row" (click)="OwnVisible = !OwnVisible">
    <p [ngClass]="{'darkVersion': moduleHandling.CurrentViewMode === 0 || moduleHandling.CurrentViewMode === 4, 'whiteVersion': moduleHandling.CurrentViewMode === 1, 'inventoryTheme': moduleHandling.CurrentViewMode === 3}">{{device.GetTranslationId() | i18next}} {{device.getComponentNumber()}}:  {{device.PlcKey.toUpperCase()}}</p>
    <svg class="updownicon" *ngIf="!OwnVisible" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 4.58 9.16">
      <defs>
        <style>.cls-1{fill:#1eb4eb;}</style>
      </defs>
      <title>clap</title>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Bearbeiten_S4" data-name="Bearbeiten S4">
          <polygon class="cls-1" points="0 9.16 0 0 4.58 4.58 0 9.16"/>
        </g>
      </g>
    </svg>
    <svg class="updownicon" *ngIf="OwnVisible" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 9.16 4.58">
      <defs>
        <style>.cls-1{fill:#1eb4eb;}</style>
      </defs>
      <title>unclap</title>
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Bearbeiten_S4" data-name="Bearbeiten S4">
          <polygon class="cls-1" points="0 0 9.16 0 4.58 4.58 0 0"/>
        </g>
      </g>
    </svg>
    <button mat-icon-button class="navbutton" *ngIf="HaveDownloadFeature()" (click)="RequestDownload()">
      <mat-icon>get_app</mat-icon>
    </button>
    <button mat-icon-button class="navbutton" *ngIf="HaveUploadFeature()"  (click)="RequestUpload()">
      <mat-icon>publish</mat-icon>
    </button>
  </div>


  <div class="contentd flex-col" *ngIf="OwnVisible">
    <table *ngFor="let dv of GetConfigValues()" class="elements flex-col">
      <tr class="elementRow flex-row stretch-row" [ngClass]="{'elementRowConf': ShowConfig(dv)}" *ngIf="ShowConfigurator(dv) && dv.States.length === 0" (click)="startEditing(dv)">
        <td class="elementText descriptionText" *ngIf="dv.States.length === 0"><p class="contentp">{{dv.TranslationId | i18next}}</p></td>
        <td class="elementText flex-row">
          <p *ngIf="!_editing" class="contentp"  >{{GetCurrentValue(dv)}}</p>
          <input *ngIf="_editing" 
            type="number" 
            [(ngModel)]="dv.CurrentValue" 
            (blur)="stopEditing(dv)" 
            (keydown.enter)="stopEditing(dv)"
            [min]="dv.MinValue"
            [max]="dv.MaxValue"
            [step]="dv.StepSize"
            #value_input 
            class="input_value touch-friendly"/>
          <p *ngIf="dv.States.length === 0" class="contentp">{{GetUnitTranslation(dv)}}</p>
        </td>
      </tr>
      <tr class="elementRow flex-row stretch-row" *ngIf="ShowConfigurator(dv)">
        <app-module-device-configurator *ngIf="ShowConfig(dv)" [deviceValue]="dv" (OnValueChanged)="ValueChanged($event)"></app-module-device-configurator>
      </tr>
      <tr class="elementRow flex-row stretch-row" [ngClass]="{'elementRowConf': ShowConfig(dv)}" *ngIf="!ShowConfigurator(dv)">
        <td style="width: 40%" class="elementText"><p class="contentp">{{dv.TranslationId | i18next}}</p></td>
      </tr>
      <tr class="elementRow flex-row stretch-row" [ngClass]="{'elementRowConf': ShowConfig(dv)}" *ngIf="!ShowConfigurator(dv)">
        <td style="width: 40%" class="elementText"><p class="contentp3">{{'COMPONENTS.COMPONENT_CONFIG.NOTCONFIGURABLE' | i18next }}</p></td>
      </tr>
    </table>

    <div class="sendtodevicediv flex-row" *ngIf="switchMode === 'ServiceMode'">
      <button class = "flex-1 justify-end center-self" *ngIf="OwnVisible" mat-button (click)="SendToModule()">
        <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 32 32">
          <g>
            <path id="path1" transform="rotate(0,16,16) translate(0.279504676042315,0) scale(0.999999642372259,0.999999642372259)  " d="M0,10.973009L10.807991,10.973009 10.807991,12.938005 1.9649979,12.938005 1.9649979,30.035014 29.476004,30.035014 29.476004,12.938005 21.616013,12.938005 21.616013,10.973009 31.441001,10.973009 31.441001,32.00001 0,32.00001z M15.353975,0L23.097964,7.4989915 21.730965,8.9109898 16.333976,3.6848745 16.333976,23.535998 14.368972,23.535998 14.368972,3.6798954 8.9729832,8.8829894 7.6079853,7.4689913z" />
          </g>
        </svg>
      </button>
    </div>

  </div>

</section>

import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, FormGroup, Validators} from '@angular/forms';
import {BluectrlApiService} from '../../../services/bluectrl-api.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ServerCommunicationService} from '../../../services/server-communication.service';

@Component({
  selector: 'app-new-module',
  templateUrl: './new-module.component.html',
  styleUrls: ['./new-module.component.css']
})
export class NewModuleComponent implements OnInit {


  serial: string;

  errorText: string;

  public groups: any[];
  public names: any[];
  public versions: any[];
  public organisations: any[];

  public selectableNames: any[];
  public selectableVersions: any[];

  public selectedGroup: any;
  public selectedName: any;
  public selectedVersion: any;
  public selectedOrganisation: any;
  private _serialPattern = /(B[0-9]{4}-[0-9]{3}-[0-9]{4})/i;
  private _serialPatternBox = /(B[0-9]{4}-[A-Z]{4}-[0-9]{3}-[0-9]{4})/i;


  constructor(private fb: UntypedFormBuilder,
              public apiService: BluectrlApiService,
              public server: ServerCommunicationService,
              private dialogRef: MatDialogRef<NewModuleComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
  }

  ngOnInit(): void {
    this.loadOrganisations();
    this.LoadModules();
  }

  CloseDialog() {
    this.dialogRef.close();
  }

  private LoadModules() {
    this.groups = [];
    this.names = [];
    this.versions = [];

    for (const mdl of this.server.Modules) {
      const group = mdl.Group;
      const groupId = mdl.Type.substring(0, 3);
      const name = mdl.Name;
      const nameId = mdl.Type.substring(3);
      const version = mdl.Version;

      if (!this.groups.find(ex => ex.id === groupId)) {
        this.groups.push({
          name: group,
          id: groupId
        });
      }

      if (!this.names.find(ex => ex.groupId === groupId && ex.id === nameId)) {
          this.names.push({
            groupId: groupId,
            id: nameId,
            name: nameId.length <= 3 ? name : name + " " + mdl.Type.substring(mdl.Type.length - 4), //for BlueBox distinction B1002-UNPZ-001-0001
            key: groupId + '' + nameId
          });
      }

      if (!this.versions.find(ex => ex.groupId === groupId && ex.nameId === nameId && ex.id === version)) {
        this.versions.push({
          groupId: groupId,
          nameId: nameId,
          id: version,
          key: groupId + '' + nameId + '-' + version
        });
      }

    }
  }

  public SaveModule() {
    if (this.savePossible()) {

      this.apiService.addNewModule(this.serial, this.selectedOrganisation.id).subscribe((response: any) => {
        this.dialogRef.close(true);
      }, error => {
        console.log(error);
      });
    }
  }
  private loadOrganisations() {
    this.apiService.getOrganizations().subscribe(async (organizations: any) => {
      if (organizations.length > 0) {
        this.organisations = organizations;
      }
    });
  }


  public groupSelectChange(item: any) {
    this.selectedVersion = null;
    this.selectableVersions = null;
    this.selectedName = null;
    this.serial = null;
    if (item.value) {
      this.selectableNames = this.names.filter(ex => ex.groupId === item.value.id);
    } else {
      this.selectableNames = null;
    }
  }

  public namesSelectChange(item: any) {
    this.selectedVersion = null;
    this.serial = null;
    if (item.value) {
      this.selectableVersions = this.versions.filter(ex => ex.nameId === item.value.id && ex.groupId === this.selectedGroup.id);
    } else {
      this.selectedVersion = null;
    }
  }

  public versionSelectChange(item: any) {
    // set the serialnumber based on selected values and existing modules increments by 1
    this.serial = null;
    if (item?.value && this.selectedGroup && this.selectedName) {
      let serials = this.server.CustomerModules.filter(m => 
        m.Module?.Type === (this.selectedGroup.id + this.selectedName.id)
      );
      if (serials.length > 0) {
        // Find the highest number across all serials
        let maxNumber = 0;
        let basePart = '';
        for (let serial of serials) {
          const lastPart = serial.SerialNumber.split('-').pop() || '0000';
          const numberStr = lastPart.slice(-4);
          const number = parseInt(numberStr);
          if (!isNaN(number) && number != 9999 && number > maxNumber) {
            maxNumber = number;
            if (!basePart) {
              const parts = serial.SerialNumber.split('-');
              basePart = parts.length === 4 && parts[1].startsWith('UN') ? 
                `${parts[0]}-${parts[1]}` : parts[0];
            }
          }
        }
        // Increment the highest number by 1 and pad with zeros to 4 digits
        let newNumber = (maxNumber + 1).toString().padStart(4, '0');
        this.serial = item.value.key + '-' + newNumber;
      } else {
        // Create first serial number for this combination
        this.serial = `${this.selectedGroup.id}${this.selectedName.id}-${item.value.id}-0001`;
      }
    }
  }

  public savePossible(): boolean {
    this.errorText = null;
    
    if (!this.selectedOrganisation) {
      this.errorText = 'Please select organization';
      return false;
    }

    if (!this.serial) {
      this.errorText = 'Please enter serial number';
      return false;
    }

    const trimmedSerial = this.serial.trim();
    let match = trimmedSerial.match(this._serialPattern);

    if (!match) {
      match = trimmedSerial.match(this._serialPatternBox);
    }

    if (!match) {
      this.errorText = 'Please enter valid serial number';
      return false;
    }

    if (this.server.CustomerModules.find(ex => ex.SerialNumber === trimmedSerial)) {
      this.errorText = 'Entered serial number already exists';
      return false;
    }

    return true;
  }



}

<section class="flex-row">
  <div class="configuratorDiv flex-row" *ngIf="deviceValue.States.length === 0">
    <button mat-button 
      (mousedown)="onSubStart($event)"
      (mouseup)="onSubEnd($event)"
      (mouseleave)="onSubEnd($event)"
      (touchstart)="onSubStart($event)"
      (touchend)="onSubEnd($event)"
      (touchcancel)="onSubEnd($event)"
      (contextmenu)="$event.preventDefault()"
      [disabled]="ParameterDisabled()">
      <img class="minusIcon" src="/assets/general/minus.svg">
    </button>

    <p class="minVal">MIN {{deviceValue.MinValue}}</p>

    <mat-slider *ngIf="deviceValue.Direction>0" min="{{deviceValue.MinValue}}" max="{{deviceValue.MaxValue}}" step="{{deviceValue.StepSize}}" value="{{deviceValue.CurrentValue}}" (valueChange)="ValueChanged($event)" (input)="OnInputChange($event)" [disabled]="ParameterDisabled()"></mat-slider>
    <mat-slider *ngIf="deviceValue.Direction<0" min="{{deviceValue.MaxValue}}" max="{{deviceValue.MinValue}}" step="{{deviceValue.StepSize}}" value="{{deviceValue.CurrentValue}}" (valueChange)="ValueChanged($event)" (input)="OnInputChange($event)" [disabled]="ParameterDisabled()"></mat-slider>

    <p class="maxVal">{{deviceValue.MaxValue}} MAX</p>

    <div class="diractable" *ngIf="deviceValue.DirectionDependency">
      <button mat-button (click)="Backward()" *ngIf="deviceValue.Direction>0">
        <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 32 32">
          <g>
            <path id="path1" transform="rotate(360,16,16) translate(32,10) scale(-1,1)  " fill="black" d="M17.227443,0.00011515617C23.663684,-0.02380538,29.524736,3.6793081,32.000001,9.4210017L30.164003,10.212011C27.899001,4.9599484 22.372999,1.6759092 16.393995,2.0269136 11.083991,2.3439173 6.4269894,5.5139551 4.3359879,10.08601L10.825992,9.1769989 11.102991,11.158022 2.9010238,12.307828 2.9130002,12.424029 0.92401315,12.629046 0,3.6640635 1.9890176,3.4590459 2.5740291,9.1351464 2.6108104,9.0557344C5.0485648,3.9273926 10.30235,0.38714385 16.273994,0.030889273 16.592979,0.011482954 16.910905,0.0012919903 17.227443,0.00011515617z" />
          </g>
        </svg>
      </button>
      <button mat-button (click)="Forward()" *ngIf="deviceValue.Direction<0">
        <svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 32 32">
          <g>
            <path id="path1" transform="rotate(0,16,16) translate(0,9.68547677993774) scale(1,1)  " fill="black" d="M17.227443,0.00011515617C23.663684,-0.02380538,29.524736,3.6793081,32.000001,9.4210017L30.164003,10.212011C27.899001,4.9599484 22.372999,1.6759092 16.393995,2.0269136 11.083991,2.3439173 6.4269894,5.5139551 4.3359879,10.08601L10.825992,9.1769989 11.102991,11.158022 2.9010238,12.307828 2.9130002,12.424029 0.92401315,12.629046 0,3.6640635 1.9890176,3.4590459 2.5740291,9.1351464 2.6108104,9.0557344C5.0485648,3.9273926 10.30235,0.38714385 16.273994,0.030889273 16.592979,0.011482954 16.910905,0.0012919903 17.227443,0.00011515617z" />
          </g>
        </svg>
      </button>
    </div>

    <button mat-button 
      (mousedown)="onAddStart($event)"
      (mouseup)="onAddEnd($event)"
      (mouseleave)="onAddEnd($event)"
      (touchstart)="onAddStart($event)"
      (touchend)="onAddEnd($event)"
      (touchcancel)="onAddEnd($event)"
      (contextmenu)="$event.preventDefault()"
      [disabled]="ParameterDisabled()">
      <img src="/assets/general/plus.svg">
    </button>
  </div>
  <p class="dropdownText" *ngIf="deviceValue.States.length > 0">{{deviceValue.TranslationId | i18next}}</p>
  <div class="SelectorDiv flex-row" *ngIf="deviceValue.States.length > 0 && deviceValue.VariableType !== 'SemiColonSeparatedArray'" [ngClass]="{'SelectorDivDisabled': ParameterDisabled()}">
    <mat-select class="selectElement" value="{{deviceValue.getValue()}}" (valueChange)="ValueChangedInSelector($event)" [disabled]="ParameterDisabled()">
      <mat-select-trigger>
        {{GetTranslationOfState(deviceValue.getValue())}}
      </mat-select-trigger>
      <mat-option *ngFor="let o of deviceValue.States" value="{{o.State}}">
        {{GetStateTranslation(o)}}
      </mat-option>
    </mat-select>
  </div>
  <div class="SelectorDiv flex-row" *ngIf="deviceValue.States.length > 0 && deviceValue.VariableType === 'SemiColonSeparatedArray'" [ngClass]="{'SelectorDivDisabled': ParameterDisabled()}">
    <mat-select class="selectElement" value="{{GetTranslationOfState(deviceValue.getValue())}}" (valueChange)="ValueChangedInSelector($event)" [disabled]="ParameterDisabled()">
      <mat-select-trigger>
        {{GetTranslationOfState(deviceValue.getValue())}}
      </mat-select-trigger>
      <mat-option *ngFor="let o of deviceValue.States" value="{{o.State}}">
        {{GetStateTranslation(o)}}
      </mat-option>
    </mat-select>
  </div>
</section>

<section class="flex-col stretch-self" [ngClass]="{'sectionLive': moduleHandling.CurrentViewMode === 1, 'sectionDesign': moduleHandling.CurrentViewMode === 0}" >
  <div class="header flex-row stretch-row">
    <div class = "flex-row flex-1 justify-end center-self">
      <button mat-button (click)="CloseDialog()">
        <img *ngIf="moduleHandling.CurrentViewMode === 1" [ngClass]="{'closeBlue': moduleHandling.CurrentViewMode === 1, 'closeBlack': moduleHandling.CurrentViewMode === 0}" src="/assets/general/close.svg">
        <img *ngIf="moduleHandling.CurrentViewMode === 0" [ngClass]="{'closeBlue': moduleHandling.CurrentViewMode === 1, 'closeBlack': moduleHandling.CurrentViewMode === 0}" src="/assets/general/close_w.svg">
      </button>
    </div>
  </div>
  <div class="filterdiv flex-row">
    <div class="userselection flex-col">
      <div *ngIf="_selectedUser" class="selected flex-row center-self">
        <p class="label">{{'COMMON.AUTHOR' | i18next }}</p>
        <p class="info">{{_selectedUser.FirstName}} {{_selectedUser.LastName}}</p>
        <button mat-button class="smallbutton" (click)="RemoveUserFilter()">
          <svg xmlns="http://www.w3.org/2000/svg" height="10" width="10" viewBox="0 0 32 32">
            <g>
              <rect height="32" width="32" fill="none" />
              <path id="path1" transform="rotate(0,16,16) translate(0.00249958053228738,0) scale(1.00000005960465,1.00000005960465)  " d="M29.590818,0C30.206963,0 30.823227,0.23449993 31.292229,0.70349979 32.229256,1.6405001 32.229256,3.1605005 31.292229,4.1004992L19.398003,15.998498 31.292229,27.892495C32.229256,28.829499 32.229256,30.349496 31.292229,31.294496 30.354227,32.2315 28.827164,32.2315 27.890139,31.294496L15.997988,19.399499 4.1047992,31.296502C3.1677734,32.234498 1.6397347,32.234498 0.70276969,31.296502 -0.23425656,30.352502 -0.23425656,28.832498 0.70276969,27.894502L12.596934,15.998498 0.70276969,4.1024981C-0.23425656,3.1654978 -0.23425656,1.6454973 0.70276969,0.7054987 1.6397347,-0.23150158 3.1677734,-0.23150158 4.1047992,0.7054987L15.99695,12.597497 27.890139,0.70349979C28.35865,0.23449993,28.974674,0,29.590818,0z" />
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="availableelements flex-row">
    <div class="listcontainer flex-col">
      <div class="flex-col center-self" >
        <h1 class="headlineRecipe center-self"> {{'DIALOGS.LOADRECIPE.TITLE' | i18next }} </h1>
        <div class="searchBar" style="display: flex; flex-direction: row; box-sizing: border-box;">
          <mat-form-field [ngClass]="{'invertedWhite': moduleHandling.CurrentViewMode === 1}" class="inputsx">
            <input matInput placeholder="{{'COMPONENTS.INVENTORY.PLACEHOLDER_SEARCH' | i18next }}" [formControl]="searchTerm" class="search-input">
          </mat-form-field>
        </div>
      </div>
      <div class="downscrollarrow flipArrow flex-col center-self">
        <button mat-button class="arrowbtn" (click)="ScrollUp()" *ngIf="UpScrolling()">
          <img class="scrollArrowBlue" src="/assets/general/arrow_blue.svg">
        </button>
      </div>
      <!--wrap!-->
      <div class="elementlist flex-col flex-column-wrap" #recipeContainer>
        <!--wrap; flex-direction.lt-sm: column-->
        <div class="buttonsWrapper gap-10 flex-row justify-start flex-wrap">
          <ng-container *ngFor="let rec of filteredRecipies">
            <!--fxFlex.lt-md 0 1 50 32; fxFlex.lt-sm 0.7-->
            <div
              class="containerElement flex-0-1-33-32 flex-col"
              [ngClass]="{'validContaunerElement': moduleHandling.CurrentViewMode === 1 && rec.valid, 'nonvalidContainerElement': moduleHandling.CurrentViewMode === 1 && !rec.valid}"
              (click)="SelectFile(rec)">
              <p class="name">{{rec.name}}</p>
              <p class="descr">{{rec.description}}</p>
              <div class="flex-row">
                <p class="date">{{rec.created | date: "dd-MM-yyyy"}}</p>
                <img class="globalIcon" *ngIf="IsRecipeOnline(rec)" [ngClass]="{'invertedWhite': moduleHandling.CurrentViewMode === 0, 'invertedBlack': moduleHandling.CurrentViewMode === 1}" src="/assets/general/receive_globally.svg">
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="downscrollarrow flex-col center-self">
        <button mat-button class="arrowbtn" (click)="ScrollDown()" *ngIf="DownScrolling()">
          <img class="scrollArrowBlue bottomArrow" src="/assets/general/arrow_blue.svg">
        </button>
      </div>
    </div>
    <div class="horline" [ngClass]="{'horlinegray': moduleHandling.CurrentViewMode === 1}"></div>
    <div class="selectedElement">
      <div *ngIf="selectedRecipie" class="flex-col stretch-self">
        <div class="rightClickMenu flex-row stretch-self">
          <p class="selHeader">{{'DIALOGS.LOADRECIPE.DETAILS' | i18next }}</p>
          <mat-menu #appMenu="matMenu" class="loadRightMenu">
              <img (click)="DeleteRecipie()" *ngIf="moduleHandling.CurrentViewMode === 0" src="/assets/general/delete_white.svg">
              <img (click)="DeleteRecipie()" *ngIf="moduleHandling.CurrentViewMode === 1" src="/assets/general/delete_blau.svg">
          </mat-menu>
          <button class="menuDots" mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
        <img class="fileGraphic" [ngClass]="{'invertedWhite': moduleHandling.CurrentViewMode === 0, 'invertedBlack': moduleHandling.CurrentViewMode === 1}" src="/assets/general/file_graphic.svg" (click)="LoadRecipie()">
        <p class="info" style="padding-bottom:10px;">NAME: {{selectedRecipie.name}}</p>
        <div class="selCreator flex-row center-self" *ngIf="false">
          <p class="label">{{'COMMON.AUTHOR' | i18next }}</p>
          <p class="info">{{GetUserById(selectedRecipie.author).FirstName}} {{GetUserById(selectedRecipie.author).LastName}}</p>
        </div>
        <div class="selDate flex-row center-self">
          <p class="label">{{'COMMON.DATE' | i18next }}:</p>
          <p class="info">{{selectedRecipie.created | date: "dd-MM-yyyy HH:mm"}}</p>
        </div>
        <div class="selVersion flex-row center-self">
          <p class="label">{{'COMMON.MODIFIED' | i18next }}:</p>
          <p class="info">{{selectedRecipie.modified | date: "dd-MM-yyyy HH:mm"}}</p>
        </div>
        <div class="selValide flex-row center-self">
          <p class="label">{{'COMMON.VALID' | i18next }}</p>
          <p *ngIf="selectedRecipie.valid" class="info">{{'COMMON.YES' | i18next }}</p>
          <p *ngIf="!selectedRecipie.valid" class="info">{{'COMMON.NO' | i18next }}</p>
        </div>
        <div class="loadButton" (click)="LoadRecipie()">
          <p class="loadText">{{'DIALOGS.LOADRECIPE.LOAD' | i18next }}</p>
        </div>

        <div class="loadButton" (click)="CopyRecipe()" *ngIf="!connectivity.inOfflineMode">
          <p class="loadText">{{'DIALOGS.LOADRECIPE.COPY' | i18next }}</p>
        </div>
      </div>
    </div>
  </div>
</section>

import {EventEmitter, Injectable} from '@angular/core';
import {ModuleDesignComponent} from '../components/elements/module-design/module-design.component';
import {ModeSwitch} from '../classes/enums/mode-switch.enum';
import {Conveyor} from '../classes/conveyor';
import {CustomerModule} from '../classes/customer-module';
import {EndPointTypes} from '../classes/enums/end-point-types.enum';
import {Project} from '../classes/project';
import {ServerCommunicationService} from './server-communication.service';
import {ModulePlan} from '../classes/module-plan';
import {ModuleRotations} from '../classes/enums/module-rotations.enum';
import {ViewCode} from '../classes/enums/view-code.enum';
import {ProjectValidationCodes} from '../classes/enums/project-validation-codes.enum';
import {CookieHandlingService} from './cookie-handling.service';
import {DatabaseService} from './database.service';
import {UserManagementService} from './user-management.service';
import {User} from '../classes/user';
import {ConveyorLenghtType} from '../classes/enums/conveyor-lenght-type.enum';
import {ModuleComponent} from '../classes/module-component';
import * as uuidV4 from 'uuid';
import {HttpClient} from '@angular/common/http';
import {MessageBoxComponent} from '../components/dialogs/message-box/message-box.component';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import {ConfirmMessageBoxComponent} from '../components/dialogs/confirm-message-box/confirm-message-box.component';
import {RuleNotficationDialogComponent} from '../components/dialogs/rule-notfication-dialog/rule-notfication-dialog.component';
import {ModuleConnections} from '../classes/enums/module-connections.enum';
import {BluectrlTranslateService} from './bluectrl-translate.service';
import {MessageHandlingService} from './v2/message-handling.service';
import {SupportModulePlan} from '../classes/support-module-plan';
import {DemoModuleStateComponent} from '../components/dialogs/demo-module-state/demo-module-state.component';
import {DemoHandlingService} from './demo-handling.service';
import {QuestionBoxComponent} from '../components/dialogs/question-box/question-box.component';
import {environment} from '../../environments/environment';
import {NotificationEntry} from '../classes/notificationEntry';

@Injectable({
  providedIn: 'root'
})
export class ModuleHandlingService {


  private modules: ModuleDesignComponent[] = [];
  private activeModule: ModulePlan;
  public activeOnboardingServiceModulePlan: SupportModulePlan;
  public sqno = 0;
  public CurrentViewMode: ViewCode;
  public LoadRecipeFromInventoryInfo: any;
  private _activeProject: Project;
  public LoadCookieRequested = false;
  private recipieToLoad: any;
  public InitActive = false;
  public MasterSerialNumber: string;
  public CurrentClusterId: string;
  public MasterIp: string;
  public ClusterInitialized = false;
  public ClusterConnected = false;
  public safetyInitFinished = false;
  public clusterResetRequested = false;
  public modulesrunning = false;
  public PopUpNotifications: any[] = [];
  public RuleNotificationActive = false;
  public ClusterInitCurrentlyRunning = false;
  public DemoView = false;
  public SupportSettingsToShow = false;
  public OnModuleConnectionPointClicked = new EventEmitter();
  public OnConveyorBeltLengthChange = new EventEmitter<Conveyor>();
  public OnConveyorConnectionPointClicked = new EventEmitter<Conveyor>();
  public OnModuleDeleted = new EventEmitter();
  public OnModuleChange = new EventEmitter();
  public OnCloseModuleSettings = new EventEmitter();
  public OnModuleSettingsSaved = new EventEmitter<CustomerModule>();
  public OnOpenModuleSettings = new EventEmitter();
  public OnOpenSupportModuleSettings = new EventEmitter();
  public OnModuleDeviceSelected = new EventEmitter<ModuleComponent>();
  public OnViewModeChanged = new EventEmitter<ViewCode>();
  public OnModuleRunningState = new EventEmitter();
  public OnUserLoggedOut = new EventEmitter<any>();
  public OnUserLoggedIn = new EventEmitter<any>();
  public OnProjectLoaded = new EventEmitter<any>();
  public OnSendRecipe = new EventEmitter();
  public RecipeLoaded = new EventEmitter<boolean>();
  public InventoryShowSettings = new EventEmitter<ModulePlan>();
  public SetModuleOfflineRequest = new EventEmitter<string>();
  public NewProjectCreated = new EventEmitter();
  public AddSupportModuleClicked = new EventEmitter();
  public ProjectSavingFinished = new EventEmitter<boolean>();
  public DataLoadingFinished = new EventEmitter();
  public dataLoadedFinishedIndicator = false;
  public sequenceNumberToOnboard = 0;


  public ModuleOnboardingRecipeLoaded = new EventEmitter<{
    success: boolean,
    MasterSerialNumber: string,
    MasterIpAddress: string,
    ClusterId: string,
    recipeId: string
  }>();

  public get Modules() {
    return this.modules;
  }

  public set ActiveProject(value: Project) {
    if (this._activeProject && value) {
      if (this._activeProject.id !== value.id) {
        this.sequenceNumberToOnboard = 0;
        this._activeProject = value;
        this.SetProjectToCookie();
        // project part of cluster id
        if (this._activeProject) {
          let projectPart = this._activeProject.name.replace(/\s/g, '');
          if (projectPart.length > 20) {
            projectPart = projectPart.substring(0, 20);
          }
          // UUID
          let uuid = uuidV4.v4();
          uuid = uuid.toString().substring(0, 8);
          // generate cluster id
          this.CurrentClusterId = projectPart + '-' + uuid;
        }
      } else {
        const splitted = this.CurrentClusterId.split('-');
        const oldUUID = splitted[splitted.length - 1];

        let projectPart = value.name.replace(/\s/g, '');
        if (projectPart.length > 20) {
          projectPart = projectPart.substring(0, 20);
        }
        // UUID

        // generate cluster id
        this.CurrentClusterId = projectPart + '-' + oldUUID;

        this._activeProject = value;
        this.SetProjectToCookie();


      }
    } else {
      this._activeProject = value;
      this.SetProjectToCookie();
      this.sequenceNumberToOnboard = 0;

      if (this._activeProject) {
        let projectPart = this._activeProject.name.replace(/\s/g, '');
        if (projectPart.length > 20) {
          projectPart = projectPart.substring(0, 20);
        }
        // UUID
        let uuid = uuidV4.v4();
        uuid = uuid.toString().substring(0, 8);
        // generate cluster id
        this.CurrentClusterId = projectPart + '-' + uuid;
      }
    }
  }

  public get ActiveProject(): Project {
    return this._activeProject;
  }

  constructor(
    public server: ServerCommunicationService,
    public cookieHandling: CookieHandlingService,
    public database: DatabaseService,
    public usermgmnt: UserManagementService,
    public translate: BluectrlTranslateService,
    public messageHandling: MessageHandlingService,
    public demoHandling: DemoHandlingService,
    public dialog: MatDialog,
    private http: HttpClient) {

    this.usermgmnt.userLoggedOut.subscribe(this.UserLoggedOut.bind(this));
    this.usermgmnt.DataLoadingFinished.subscribe(this.NewUserLoggedIn.bind(this));
    this.server.OnModulesLoaded.subscribe(this.ServerModulesLoaded.bind(this));
    this.messageHandling.ModuleMessageReceived.subscribe(this.PopUpNotificationHandling.bind(this));
    this.messageHandling.NotificationsReceived.subscribe(this.UpdateNotifications.bind(this));
    this.messageHandling.ModuleStateChanged.subscribe(this.updateModuleStates.bind(this));
    this.messageHandling.ModuleParameterChangeRequest.subscribe(this.moduleParameterChangeRequest.bind(this));
    this.PopUpNotifications = this.server.LoadPopupNotofcations();


  }

  public dataLoadedFinished() {
    this.dataLoadedFinishedIndicator = true;
    this.DataLoadingFinished.emit();
  }

  public setClusterInitialized(initialized: boolean) {
    this.ClusterInitialized = initialized;
    if (this._activeProject) {
      for (const pl of this._activeProject.Modules) {
        pl.updateClusterInitialized(this.ClusterInitialized);
      }
    }
  }

  public setClusterConnected(connected: boolean) {
    this.ClusterConnected = connected;
    if (this._activeProject) {
      for (const pl of this._activeProject.Modules) {
        pl.updateClusterInitialized(this.ClusterInitialized);
      }
    }
  }

  public moduleParameterChangeRequest(msg: any) {
    if (this.CurrentViewMode === ViewCode.live) {
      if (this.ActiveProject) {
        if (this.ActiveProject.Modules || this.ActiveProject.SupportModules) {
          const mdlpl = this.ActiveProject.Modules.find(ex => ex.customerModule && ex.customerModule.SerialNumber === msg.ctxId);
          if (mdlpl) {
            mdlpl.setRequestedParameter(msg);
          } else {
            if (this.ActiveProject.SupportModules) {
              const smdlpl = this.ActiveProject.SupportModules.find(ex => ex.customerModule &&
                ex.customerModule.SerialNumber === msg.ctxId);

              if (smdlpl) {
                smdlpl.setRequestedParameter(msg);
              }
            }
          }
        }
      }
    }
  }

  public updateModuleStates(state: any) {
    if (this.CurrentViewMode === ViewCode.live) {
      if (this.ActiveProject) {
        if (this.ActiveProject.Modules || this.ActiveProject.SupportModules) {
          const mdlpl = this.ActiveProject.Modules.find(ex => ex.customerModule && ex.customerModule.SerialNumber === state.ctxId);
          if (mdlpl) {
            mdlpl.updateModuleState(state.state);
          } else {

            if (this.ActiveProject.SupportModules) {
              const smdlpl = this.ActiveProject.SupportModules.find(ex => ex.customerModule &&
                ex.customerModule.SerialNumber === state.ctxId);

              if (smdlpl) {
                smdlpl.updateModuleState(state.state);
              }
            }
          }
        }
      }
    }
  }

  public InventoryModuleSettingsOpen(plan: ModulePlan) {
    this.InventoryShowSettings.emit(plan);
  }

  public InventoryModuleSettingsClosed() {
    this.InventoryShowSettings.emit(null);
  }

  public NewProject() {
    this.sequenceNumberToOnboard = 0;
    this.NewProjectCreated.emit();
    this.ActiveProject = null;
    this.modules = [];
    this.activeModule = null;
    this.cookieHandling.RemoveCurrentConfig(this.usermgmnt.GetCurrentUser());
    this.demoHandling.currentModulePlan = this.activeModule;
  }

  public CouldUpdateApp(): boolean {
    return !this.InitActive;
  }

  public get ActiveModule(): ModulePlan {
    return this.activeModule;
  }

  public PopUpNotificationHandling(data: any) {
    const nt = this.PopUpNotifications.find(ex => ex.code === data.code);
    if (nt) {
      // FIND MODULE

      if (environment.production) {
        if (data.code === 'NF-002') {
          return;
        }
      }

      const serialSplit = data.ctxId.split('-');

      if (serialSplit.length > 0) {

        let type = serialSplit[0];
        if (type === 'B1002') {
          type = serialSplit[0] + '-' + serialSplit[1];
        }
        let version = serialSplit[1];
        if (version === 'B1002') {
          version = serialSplit[2];
        }

        const module = this.server.Modules.find(ex => ex.Type === type && ex.Version === version);
        if (module) {
          const defaulttxt = this.translate.GetTranslation('MESSAGEBOX.HEADERS.MESSAGEFROMMODULE');
          const msg = defaulttxt + ' ' + module.Group + ' ' + module.Name + ' ' + data.ctxId;
          this.dialog.open(ConfirmMessageBoxComponent,
            {panelClass: 'panelclass', data: {header: msg, content: nt.text}});


        }
      }

    }

  }

  public openModuleStateDemo() {
    if (this.ActiveModule) {

      this.DemoView = true;
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'settingControl';
      dialogConfig.data = null;

      const dialogRef = this.dialog.open(DemoModuleStateComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
      });
    }
  }

  public openSupportModuleStateDemo() {
    if (this.ActiveModule) {

      const support = this.getSupportModuleForModulePlan(this.ActiveModule);
      if (support) {
        this.DemoView = true;
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'settingControl';
        dialogConfig.data = 'support';

        const dialogRef = this.dialog.open(DemoModuleStateComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(data => {
          this.DemoView = false;
        });
      }
    }
  }

  public showMessage(headerKey: string, contentKey: string) {
    const msg = this.translate.GetTranslation(headerKey);
    const content = this.translate.GetTranslation(contentKey);

    this.dialog.open(ConfirmMessageBoxComponent,
      {panelClass: 'panelclass', data: {header: msg, content: content}});
  }

  public ServerModulesLoaded() {

    if (this.recipieToLoad) {

      // this.ActiveProject = new Project('dd');
      const project2 = new Project('NEUES PROJECT');

      this.LoadRecipie(project2, this.recipieToLoad, this.usermgmnt.GetCurrentUser().CustomerId, this.server);
      this.ActiveProject = project2;
    } else if (this.LoadCookieRequested) {
      this.LoadCookieProject();

    }
  }

  public createTestRecipe() {
    if (this.ActiveProject) {

      if (this.activeModule) {

      }

      const rec = this.CreateRecipe(this.ActiveProject);
      try {
        console.log(JSON.parse(rec));
      } catch {
        console.log(rec);
      }
    }
  }

  public AddModule(module: ModuleDesignComponent) {

    if (!this.modules.some(md => md.modulePlan.customerModule.CustomerModuleId === module.modulePlan.customerModule.CustomerModuleId)) {
      this.modules.push(module);
      module.modulePlan.SetHandling(this);

      module.SetModuleActive();
      this.activeModule = this.getModulePlanFromComponent(module);
      this.demoHandling.currentModulePlan = this.activeModule;

      if (module.modulePlan) {
        if (module.modulePlan.modul) {
          module.modulePlan.modul.RuleConfirmationRequired.subscribe(this.RuleChangeConfirmation.bind(this));
        }
      }

      if (module.modulePlan) {
        if (module.modulePlan.customerModule) {
          // module.modulePlan.customerModule.Module.RuleConfirmationRequired.subscribe(this.RuleChangeConfirmation.bind(this));
        }
      }

    }
    this.SetAllOtherModuleInactive(module);
  }

  public getSupportModuleForModulePlan(moduleplan: ModulePlan): SupportModulePlan {
    if (this.ActiveProject) {
      if (this.ActiveProject.SupportModules) {
        for (const support of this.ActiveProject.SupportModules) {
          if (moduleplan.customerModule) {
            const sp = support.getSupportModuleByConnectedId(moduleplan.customerModule.CustomerModuleId);
            if (sp) {
              return sp;
            }

          }
        }
      }
    }
    return null;
  }

  public supportModuleAlreadyExists(customerModule: CustomerModule): SupportModulePlan {
    if (this.ActiveProject) {
      if (this.ActiveProject.SupportModules) {
        for (const support of this.ActiveProject.SupportModules) {
          if (support.customerModule.CustomerModuleId === customerModule.CustomerModuleId) {
            return support;
          }
        }
      }
    }
    return null;
  }

  public addNewSupportModulePlan(support: SupportModulePlan): boolean {
    if (this.ActiveProject) {
      if (!this.ActiveProject.SupportModules) {
        this.ActiveProject.SupportModules = [];
      }

      this.ActiveProject.SupportModules.push(support);
      return true;

    }

    return false;
  }

  public RuleChangeConfirmation(data: any) {

    if (!this.RuleNotificationActive) {
      this.RuleNotificationActive = true;
      const dialogData = {
        TriggerComponent: data.TriggerComponent,
        TriggerParameter: data.TriggerParameter,
        TriggerValue: data.TriggerValue,
        TargetComponent: data.TargetComponent,
        TargetParameter: data.TargetParameter,
        TargetValue: data.TargetValue,
        ActionName: data.rule.ActionName
      };

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data = dialogData;
      const dialogRef = this.dialog.open(RuleNotficationDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        this.RuleNotificationActive = false;
        if (result === true) {
          try {
            const numtar = Number(data.valueToSet);

            if (!isNaN(numtar)) {
              data.parameter.SetValue(numtar);
            } else {
              data.parameter.SetValue(data.valueToSet);
            }
          } catch {
            data.parameter.SetValue(data.valueToSet);
          }
        }
      });
    }
  }

  public UserLoggedOut(user: User) {
    this.ActiveProject = null;
    this.OnUserLoggedOut.emit(null);
    this.resetOnboardingPerModule();
  }

  public NewUserLoggedIn() {
    if (this.usermgmnt.DataLoaded) {

      this.SetProjectFromCookie();
      this.OnUserLoggedIn.emit(null);

    }
  }

  public resetOnboardingPerModule() {
    if (this.ActiveProject) {
      for (const md of this.ActiveProject.Modules) {
        md.leaveLiveMode();
      }
      if (this.ActiveProject.SupportModules) {
        for (const md of this.ActiveProject.SupportModules) {
          md.leaveLiveMode();
        }
      }
    }
  }

  public SetViewMode(viewMode: ViewCode) {
    if (this.CurrentViewMode === ViewCode.live && viewMode !== ViewCode.live) {
      if (this.ActiveProject) {
        for (const md of this.ActiveProject.Modules) {
          md.leaveLiveMode();
        }
        if (this.ActiveProject.SupportModules) {
          for (const md of this.ActiveProject.SupportModules) {
            md.leaveLiveMode();
          }
        }
      }
    } else {
      this.sequenceNumberToOnboard = 0;
    }

    this.CurrentViewMode = viewMode;
    this.OnViewModeChanged.emit(this.CurrentViewMode);
    // CHEK RULES
    this.UpdateModuleRules();

  }

  private ResetServiceTasks() {
    if (this._activeProject) {
      for (const moduleplan of this._activeProject.Modules) {
        moduleplan.customerModule.ResetSetupTasks();
      }

      if (this._activeProject.SupportModules) {
        for (const moduleplan of this._activeProject.SupportModules) {
          moduleplan.customerModule.ResetSetupTasks();
        }
      }
    }
  }

  public UpdateModuleRules() {
    if (this._activeProject) {
      if (this._activeProject.Modules) {
        for (const modl of this._activeProject.Modules) {
          if (modl) {
            if (modl.customerModule) {
              modl.customerModule.Module.CheckAllRules();
            }
          }
        }
      }
      if (this._activeProject.SupportModules) {
        for (const modl of this._activeProject.SupportModules) {
          if (modl) {
            if (modl.customerModule) {
              modl.customerModule.Module.CheckAllRules();
            }
          }
        }
      }
    }
  }

  public RemoveAllModules() {
    // this.activeModule = null;
    this.modules = [];
  }

  public IsModuleActive(): boolean {
    if (this.activeModule !== null && this.activeModule !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  public SetProjectToCookie(project: Project = null) {

    if (project) {
      this.ActiveProject = project;


      this.cookieHandling.SetCurrentConfig(
        this.CreateRecipeDatabase(project),
        this.ActiveProject,
        this.ValidateProject().length <= 0,
        this.usermgmnt.GetCurrentUser());

    } else if (this.ActiveProject && this.ActiveProject.Modules.length > 0) {
      this.cookieHandling.SetCurrentConfig(
        this.CreateRecipeDatabase(this.ActiveProject),
        this.ActiveProject,
        this.ValidateProject().length <= 0,
        this.usermgmnt.GetCurrentUser());
    }
  }

  public LoadCookieProject() {
    const vl = this.cookieHandling.GetCurrentConfig(this.usermgmnt.GetCurrentUser());

    if (vl && vl.id) {
      // GET FROM DB
      this.database.recipes.toArray().then(rec => {
        const lookedfor = rec.find(ex => ex.id === vl.id);
        if (lookedfor) {
          const dt = JSON.parse(JSON.stringify(lookedfor));
          const project = new Project(dt.name);
          project.name = dt.name;
          project.created = dt.created;
          project.modified = dt.modified;
          project.public = dt.public;
          project.author = dt.author;
          project.id = dt.id;
          project.valid = dt.valid;
          project.description = dt.description;
          if (dt.tempSaved) {
            project.tempSaved = dt.tempSaved;
            if (!project.name) {
              project.name = 'temp';
            }
          }
          this.ActiveProject = project;

          this.LoadRecipie(project, dt.recipe, this.usermgmnt.currentUser.CustomerId, this.server);
          // CHEK RULES
          this.UpdateModuleRules();
        }
      });
    }
    if (vl) {

      if (this.CurrentViewMode === ViewCode.live) {
        if (!vl.valid) {
          // valid = false;
        }
      }
    }
  }

  public SetProjectFromCookie(validate: boolean = false) {


    if (!this.ActiveProject) {
      if (!this.server.Loaded) {
        this.LoadCookieRequested = true;
      } else {
        this.LoadCookieProject();

      }
    } else if (this.ActiveProject && (this.ActiveProject.Modules.filter(ex => ex.seqno < 0) ||
      (this.ActiveProject.SupportModules.filter(ex => ex.seqno < 0)))) {
      this.ActiveProject = null;
      if (!this.server.Loaded) {
        this.LoadCookieRequested = true;
      } else {
        this.LoadCookieProject();
      }
    } else if (this.ActiveProject) {
      let maxSeq = 0;
      let modules = 0;
      for (const md of this.ActiveProject.Modules) {
        if (md.seqno > maxSeq) {
          maxSeq = md.seqno;
        }
        modules++;
      }

      for (const md of this.ActiveProject.SupportModules) {
        if (md.seqno > maxSeq) {
          maxSeq = md.seqno;
        }
        modules++;
      }

      if (maxSeq !== modules - 1) {
        this.ActiveProject = null;
        if (!this.server.Loaded) {
          this.LoadCookieRequested = true;
        } else {
          this.LoadCookieProject();
        }
      }
    }
  }

  public RemoveModule(module: ModuleDesignComponent) {
    this.modules = this.modules.filter(md => md.modulePlan.customerModule.CustomerModuleId !== module.modulePlan.customerModule.CustomerModuleId);
  }

  public SetModuleActive(module: ModuleDesignComponent) {


    if (this.CurrentViewMode === ViewCode.live) {
      if (this.sequenceNumberToOnboard < module.modulePlan.seqno) {
        // SHOW MESSAGE
        const dialogRef = this.dialog.open(MessageBoxComponent,
          {
            width: 200 + 'px',
            panelClass: 'panelclass',
            data: {text: this.translate.GetTranslation('MESSAGEBOX.CONTENT.KEEPONBOARDINGORDER'), timeout: 3000}
          });
        dialogRef.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});
        return;
      }


      for (const md of this.ActiveProject.Modules.filter(ex => ex.customerModule.SerialNumberSetted)) {
        if (!md.customerModule.Initialized || !md.customerModule.FullyConfigured) {
          // FIRST FINISH the other one
          if (module.modulePlan.customerModule.CustomerModuleId !== md.customerModule.CustomerModuleId) {
            const dialogRef = this.dialog.open(MessageBoxComponent,
              {
                width: 200 + 'px', panelClass: 'panelclass', data: 'Module ' + md.modul.Group +
                  ' ' + md.modul.Name + ' ' +
                  md.customerModule.SerialNumber +
                  ' is not fully initialized, please first finish this module'
              });
            dialogRef.updatePosition({top: '0px', left: window.innerWidth / 2 + 'px'});
            return;
          }


        }
      }
    }

    module.SetModuleActive();
    const mdl = this.getModulePlanFromComponent(module);
    if (mdl) {
      this.activeModule = mdl;
      this.demoHandling.currentModulePlan = this.activeModule;
    }
    this.SetAllOtherModuleInactive(module);
  }

  private getModulePlanFromComponent(moduleComponent: ModuleDesignComponent): ModulePlan {
    return this.ActiveProject.Modules.find(ex => ex.customerModule.CustomerModuleId === moduleComponent.modulePlan.customerModule.CustomerModuleId);
  }

  public getModuleComponentFromActiveModulePlan(): ModuleDesignComponent {
    if (this.activeModule && this.modules) {
      return this.modules.find(ex => ex._modulePlan.customerModule.CustomerModuleId === this.activeModule.customerModule.CustomerModuleId);
    }
    return null;
  }

  public setConveyorAddOnValueForMagnet(conveyor: Conveyor, moduleId: string, value: boolean) {
    // GET MODULE PLAN
    if (moduleId) {
      const plan = this._activeProject.Modules.find(ex => ex.customerModule.CustomerModuleId === moduleId);

      if (plan) {
        this._activeProject.changed = true;
        plan.SetDirectionForMagnetBelt(conveyor, value);
        this.saveTempProject();
      }
    }
  }

  public GetConveyorAddOnValueForMagnet(conveyor: Conveyor, moduleId: string): any {
    // GET MODULE PLAN
    if (moduleId) {
      const plan = this._activeProject.Modules.find(ex => ex.customerModule.CustomerModuleId === moduleId);

      if (plan) {
        return plan.GetDirectionForMagnetBelt(conveyor);
      }
    }

    return null;
  }

  public getModuleComponentFromModulePlan(plan: ModulePlan): ModuleDesignComponent {
    if (this.activeModule && this.modules) {
      return this.modules.find(ex => ex._modulePlan.customerModule.CustomerModuleId === plan.customerModule.CustomerModuleId);
    }
    return null;
  }

  public SetAllOtherModuleInactive(module: ModuleDesignComponent) {
    let moduleslk;
    if (module == null) {
      moduleslk = this.modules;
    } else {
      moduleslk = this.modules.filter(md => md.modulePlan.customerModule.CustomerModuleId !== module.modulePlan.customerModule.CustomerModuleId);
    }
    for (const md of moduleslk) {
      md.SetModuleInactive();
    }
  }

  public SetModuleInactive(module: ModuleDesignComponent) {
    if (this.activeModule != null) {
      this.activeModule = null;
      this.demoHandling.currentModulePlan = this.activeModule;
    }

    this.SetAllOtherModuleInactive(null);
  }

  public ReDraw() {
    if (this.modules.length > 0) {
      if (!this.activeModule) {
        this.activeModule = this.getModulePlanFromComponent(this.modules[0]);
        this.demoHandling.currentModulePlan = this.activeModule;
      }

      this.SetModuleActive(this.getModuleComponentFromActiveModulePlan());
      this.SetAllOtherModuleInactive(this.getModuleComponentFromActiveModulePlan());
    }
  }

  public RemoveAllSerivceModes(module: ModuleDesignComponent) {
    let moduleslk;
    if (module == null) {
      moduleslk = this.modules;
    } else {
      moduleslk = this.modules.filter(md => md.modulePlan.customerModule.CustomerModuleId !== module.modulePlan.customerModule.CustomerModuleId);
    }

    for (const md of moduleslk) {
      md.modulePlan.modul.CurrentMode = ModeSwitch.AUTOMATIC;
    }
  }

  public RotateModuleClockwise() {
    if (this.activeModule !== null && this.activeModule !== undefined) {
      this.getModuleComponentFromActiveModulePlan().rotateClockwise();
    }

  }

  public RotateModuleCounterClockwise() {
    if (this.activeModule !== null && this.activeModule !== undefined) {
      this.getModuleComponentFromActiveModulePlan().rotateCounterClockwise();
    }

  }

  public DeleteModule() {
    if (this.activeModule !== null && this.activeModule !== undefined) {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'loginDialogGray';
      dialogConfig.data =
        this.translate.GetTranslation('MESSAGEBOX.CONTENT.REALLYWANTTODELETE');
      const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.OnModuleDeleted.emit();
        }
      });
    }
  }

  public ModuleConnectionPointChange() {
    this.OnModuleConnectionPointClicked.emit();
  }

  public ConveyorConnectionPointClicked(conveyor: Conveyor) {
    this.OnConveyorConnectionPointClicked.emit(conveyor);
  }

  public AddSupportModuleClick() {
    this.AddSupportModuleClicked.emit();
  }

  public ChangeModule() {
    if (this.activeModule !== null && this.activeModule !== undefined) {
      this.OnModuleChange.emit();
    }
  }

  public CloseModuleSettings() {
    this.OnCloseModuleSettings.emit();
  }

  public SaveModuleSettings(customerModule: CustomerModule) {
    this.OnModuleSettingsSaved.emit(customerModule);
  }

  public OpenModuleSettings() {

    if (this.activeModule !== null && this.activeModule !== undefined) {
      this.OnOpenModuleSettings.emit();
    }
  }

  public ReopenModuleSettings() {
    this.CloseModuleSettings();

    setTimeout(() => {
      this.OpenModuleSettings();
    }, 100);
  }

  public OpenSupportModuleSettings() {
    if (this.activeModule !== null && this.activeModule !== undefined) {
      if (this.getSupportModuleForModulePlan(this.activeModule)) {
        this.SupportSettingsToShow = true;
        this.OnOpenSupportModuleSettings.emit();
      }
    }
  }

  public deleteSupportModuleConnection(moduleplan: ModulePlan) {
    if (moduleplan.customerModule && this.ActiveProject && this.ActiveProject.SupportModules) {
      for (const supports of this.ActiveProject.SupportModules) {
        if (supports.haveModuleById(moduleplan.customerModule.CustomerModuleId)) {
          // REMOVE CONNECTION
          supports.removeConnection(moduleplan);
        }
      }

      this.ActiveProject.SupportModules = this.ActiveProject.SupportModules.filter(ex => ex.haveConnections());
    }
  }

  public ModuleDeviceSelect(moduleDevice: ModuleComponent) {
    this.OnModuleDeviceSelected.emit(moduleDevice);
  }

  public CreateRecipeDatabase(project: Project, clusterInfo = false): any {

    const constellation = [];
    const configuration = [];
    // const composition = [];
    const moduleclusterInfo = [];
    this.sqno = 0;

    if (project.name === 'temp') {
      clusterInfo = false;
    }

    if (project.essentialChange === true) {
      project.cluster = null;
      clusterInfo = false;
    }

    const initmd = project.Modules.find(ex => ex.isInialModule === true);

    if (initmd !== null && initmd !== undefined) {
      let comment = '';
      if (initmd.customerModule) {
        comment = initmd.customerModule.Comment;
      }

      const dd = {
        moduletype: initmd.modul.Type,
        moduleversion: initmd.modul.Version,
        modulename: initmd.modul.Group + ' ' + initmd.modul.Name,
        comment: comment,
        seqno: this.sqno,
        outputs: []
      };

      constellation.push(initmd.rotation);

      if (clusterInfo === true) {
        moduleclusterInfo.push({
          serial: initmd.customerModule.SerialNumber,
          nodeIP: initmd.customerModule.NodeIpAddress
        });
      }

      // CONFIG

      const config = [];

      const moddevs = initmd.modul.Components.filter(ex => ex.Configs.length > 0);

      for (let d = 0; d < moddevs.length; d++) {
        const mdconf = moddevs[d].Configs.filter(ex => !ex.Mode || ex.Mode !== 'Service');

        const deviceInfo = {
          name: moddevs[d].PlcKey,
          values: []
        };

        if (mdconf.length > 0) {


          for (let i = 0; i < mdconf.length; i++) {

            // HAVE SELECTION LIST?
            let addInfo = null;

            if (mdconf[i].SelectionList && mdconf[i].SelectionList.length > 0) {
              addInfo = mdconf[i].SelectionList;
            }

            deviceInfo.values.push({
              name: mdconf[i].Name,
              value: mdconf[i].CurrentValue,
              addInfo: addInfo
            });
          }

          config.push(deviceInfo);
        }
      }

      configuration.push({
        config: config
      });

      // if (initmd.customerModule !== null && initmd.customerModule !== undefined) {
      //   composition.push({
      //     type: initmd.customerModule.Module.Key,
      //     organization: initmd.customerModule.Customer});
      // } else {
      //   composition.push('');
      // }

      // this.sqno++;

      const outputtoname = [];

      let supportSqno = -1;
      let supportModulePlan: SupportModulePlan = null;

      if (project.SupportModules) {
        for (const sup of project.SupportModules) {
          if (sup.haveModuleById(initmd.customerModule.CustomerModuleId)) {
            sup.setModuleSequenceNumber(initmd, this.sqno);

            if (sup.isLastModule(initmd)) {
              this.sqno++;
              supportSqno = this.sqno;
              supportModulePlan = sup;
            }
          }
        }
      }

      this.sqno++;

      for (const outp of initmd.modul.ConveyorBelts) {
        const cp = initmd.connections.find(ex => ex.moduleConnection === outp.ConnectionPoint);
        if (cp) {
          if (cp.conveyor) {
            const conv = cp.conveyor;
            if (conv.EndPointType !== EndPointTypes.undefined) {
              if (conv.EndPointType === EndPointTypes.module) {
                const mdl = conv.EndPoint;
                if (mdl !== null && mdl !== undefined) {
                  let uniqe = mdl.CustomerModuleId;

                  const mdpl = project.Modules.find(ex => ex.customerModule.CustomerModuleId === uniqe);

                  if (mdpl !== null && mdpl !== undefined) {
                    outputtoname.push({
                      outputName: outp.OutputName,
                      connectionPoint: outp.ConnectionPoint,
                      conveyorType: conv.LenghtType,
                      module: mdpl,
                      seqno: this.sqno
                    });

                  } else {
                    outputtoname.push({
                      outputName: outp.OutputName,
                      connectionPoint: outp.ConnectionPoint,
                      conveyorType: conv.LenghtType,
                      connectionInfo: ''
                    });
                  }

                } else {
                  outputtoname.push({
                    outputName: outp.OutputName,
                    connectionPoint: outp.ConnectionPoint,
                    conveyorType: conv.LenghtType,
                    connectionInfo: ''
                  });
                }

              } else {
                outputtoname.push({
                  outputName: outp.OutputName,
                  connectionPoint: outp.ConnectionPoint,
                  conveyorType: conv.LenghtType,
                  connectionInfo: 'sink'
                });
              }
            } else {
              outputtoname.push({
                outputName: outp.OutputName,
                connectionPoint: outp.ConnectionPoint,
                conveyorType: conv.LenghtType,
                connectionInfo: ''
              });
            }
          } else {
            outputtoname.push({
              outputName: outp.OutputName,
              connectionPoint: outp.ConnectionPoint,
              connectionInfo: 'notconnected'
            });
          }
        } else {
          outputtoname.push({
            outputName: outp.OutputName,
            connectionPoint: outp.ConnectionPoint,
            connectionInfo: ''
          });
        }
      }

      if (supportSqno >= 0) {
        // ADD IT
        dd.outputs.push(this.CreateRecipeForSupportModuleDatabase(project,
          supportModulePlan,
          constellation,
          configuration,
          moduleclusterInfo,
          supportSqno,
          clusterInfo));
      }

      for (const outp of outputtoname) {
        if (!outp.connectionInfo) {
          dd.outputs.push(this.CreateRecipeForSubModuleDatabase(project,
            outp,
            constellation,
            configuration,
            moduleclusterInfo,
            clusterInfo));
        } else {
          dd.outputs.push({
            outputName: outp.outputName,
            connectionPoint: outp.connectionPoint,
            conveyorType: outp.conveyorType,
            connection: outp.connectionInfo
          });
        }
      }

      let cluster: any;

      if (clusterInfo === true) {
        cluster = {
          clusterId: this.CurrentClusterId,
          masterSerial: this.MasterSerialNumber,
          masterIp: this.MasterIp,
          modules: moduleclusterInfo
        };
      } else if (project.cluster) {
        if (project.cluster.modules) {
          if (project.cluster.modules.length === (project.Modules.length + (project.SupportModules ? project.SupportModules.length : 0))) {
            cluster = project.cluster;
          } else {
            cluster = null;
          }
        }
      } else {
        cluster = null;
      }

      project.cluster = cluster;
      return {
        tree: dd,
        constellation: constellation,
        configuration: configuration,
        cluster: cluster
      };
    }
    return null;
  }

  public CreateRecipeForSubModuleDatabase(project: Project,
                                          mdl: any,
                                          constellation: any[],
                                          configuration: any[],
                                          moduleclusterInfo: any[],
                                          clusterInfo: boolean): any {

    const initmd: ModulePlan = mdl.module;

    if (initmd !== null && initmd !== undefined) {
      let comment = '';

      if (initmd.customerModule) {
        comment = initmd.customerModule.Comment;
      }

      const dd = {
        moduletype: initmd.modul.Type,
        moduleversion: initmd.modul.Version,
        modulename: initmd.modul.Group + ' ' + initmd.modul.Name,
        comment: comment,
        seqno: this.sqno,
        outputs: []
      };

      constellation.push(initmd.rotation);

      if (clusterInfo === true) {
        moduleclusterInfo.push({
          serial: initmd.customerModule.SerialNumber,
          nodeIP: initmd.customerModule.NodeIpAddress
        });
      }

      // CONFIG

      const config = [];

      const moddevs = initmd.modul.Components.filter(
        ex => ex.Configs.length > 0);

      for (let d = 0; d < moddevs.length; d++) {
        const mdconf = moddevs[d].Configs.filter(ex => !ex.Mode || ex.Mode !== 'Service');

        const deviceInfo = {
          name: moddevs[d].PlcKey,
          values: []
        };

        if (mdconf.length > 0) {


          for (let i = 0; i < mdconf.length; i++) {
            // HAVE SELECTION LIST?
            let addInfo = null;

            if (mdconf[i].SelectionList && mdconf[i].SelectionList.length > 0) {
              addInfo = mdconf[i].SelectionList;
            }

            deviceInfo.values.push({
              name: mdconf[i].Name,
              value: mdconf[i].CurrentValue,
              addInfo: addInfo
            });
          }

          config.push(deviceInfo);
        }
      }

      configuration.push({
        config: config
      });

      // if (initmd.customerModule !== null && initmd.customerModule !== undefined) {
      //   composition.push({
      //     type: initmd.customerModule.Module.Key,
      //     organization: initmd.customerModule.Customer});
      // } else {
      //   composition.push('');
      // }

      // this.sqno++;

      const outputtoname = [];

      let supportSqno = -1;
      let supportModulePlan: SupportModulePlan = null;

      if (project.SupportModules) {
        for (const sup of project.SupportModules) {
          if (sup.haveModuleById(initmd.customerModule.CustomerModuleId)) {
            sup.setModuleSequenceNumber(initmd, this.sqno);

            if (sup.isLastModule(initmd)) {
              this.sqno++;
              supportSqno = this.sqno;
              supportModulePlan = sup;
            }
          }
        }
      }

      this.sqno++;


      for (const outp of initmd.modul.ConveyorBelts) {
        const cp = initmd.connections.find(ex => ex.moduleConnection === outp.ConnectionPoint);
        if (cp !== null && cp !== undefined) {
          if (cp.conveyor !== null && cp.conveyor !== undefined) {
            const conv = cp.conveyor;

            if (conv.EndPointType !== EndPointTypes.undefined) {
              if (conv.EndPointType === EndPointTypes.module) {
                const mdls = conv.EndPoint;

                if (mdls !== null && mdls !== undefined) {

                  let uniqe = mdls.CustomerModuleId;

                  const mdpl = project.Modules.find(ex => ex.customerModule.CustomerModuleId === uniqe);

                  if (mdpl !== null && mdpl !== undefined) {
                    outputtoname.push({
                      outputName: outp.OutputName,
                      connectionPoint: outp.ConnectionPoint,
                      conveyorType: conv.LenghtType,
                      module: mdpl,
                      seqno: this.sqno
                    });

                  } else {
                    outputtoname.push({
                      outputName: outp.OutputName,
                      connectionPoint: outp.ConnectionPoint,
                      conveyorType: conv.LenghtType,
                      connectionInfo: ''
                    });
                  }

                } else {
                  outputtoname.push({
                    outputName: outp.OutputName,
                    connectionPoint: outp.ConnectionPoint,
                    conveyorType: conv.LenghtType,
                    connectionInfo: ''
                  });
                }

              } else {
                outputtoname.push({
                  outputName: outp.OutputName,
                  connectionPoint: outp.ConnectionPoint,
                  conveyorType: conv.LenghtType,
                  connectionInfo: 'sink'
                });
              }
            } else {
              outputtoname.push({
                outputName: outp.OutputName,
                connectionPoint: outp.ConnectionPoint,
                conveyorType: conv.LenghtType,
                connectionInfo: ''
              });
            }
          } else {
            outputtoname.push({
              outputName: outp.OutputName,
              connectionPoint: outp.ConnectionPoint,
              connectionInfo: 'notconnected'
            });
          }
        } else {
          outputtoname.push({
            outputName: outp.OutputName,
            connectionPoint: outp.ConnectionPoint,
            connectionInfo: ''
          });
        }
      }

      if (supportSqno >= 0) {
        // ADD IT
        dd.outputs.push(this.CreateRecipeForSupportModuleDatabase(project,
          supportModulePlan,
          constellation,
          configuration,
          moduleclusterInfo,
          supportSqno,
          clusterInfo));
      }

      for (const outp of outputtoname) {
        if (outp.connectionInfo === null || outp.connectionInfo === undefined) {
          dd.outputs.push(this.CreateRecipeForSubModuleDatabase(project,
            outp,
            constellation,
            configuration,
            moduleclusterInfo,
            clusterInfo));
        } else {
          dd.outputs.push({
            outputName: outp.outputName,
            conveyorType: outp.conveyorType,
            connectionPoint: outp.connectionPoint,
            connection: outp.connectionInfo
          });
        }
      }

      return {
        outputName: mdl.outputName,
        conveyorType: mdl.conveyorType,
        connectionPoint: mdl.connectionPoint,
        connection: dd
      };

    } else {
      return {
        outputName: mdl.outputName,
        connectionPoint: mdl.connectionPoint,
        connection: ''
      };
    }
  }


  public CreateRecipeForSupportModuleDatabase(project: Project,
                                              mdl: SupportModulePlan,
                                              constellation: any[],
                                              configuration: any[],
                                              moduleclusterInfo: any[],
                                              supportSeqNo: number,
                                              clusterInfo: boolean): any {

    const initmd: SupportModulePlan = mdl;

    if (initmd !== null && initmd !== undefined) {
      let comment = '';

      if (initmd.customerModule) {
        comment = initmd.customerModule.Comment;
      }

      const dd = {
        moduletype: initmd.modul.Type,
        moduleversion: initmd.modul.Version,
        modulename: initmd.modul.Group + ' ' + initmd.modul.Name,
        comment: comment,
        seqno: supportSeqNo,
        outputs: []
      };

      constellation.push(initmd.rotation);

      if (clusterInfo === true) {
        moduleclusterInfo.push({
          serial: initmd.customerModule.SerialNumber,
          nodeIP: initmd.customerModule.NodeIpAddress
        });
      }

      // CONFIG

      const config = [];

      const moddevs = initmd.modul.Components.filter(
        ex => ex.Configs.length > 0);

      for (let d = 0; d < moddevs.length; d++) {
        const mdconf = moddevs[d].Configs.filter(ex => !ex.Mode || ex.Mode !== 'Service');

        const deviceInfo = {
          name: moddevs[d].PlcKey,
          values: []
        };

        if (mdconf.length > 0) {


          for (let i = 0; i < mdconf.length; i++) {
            // HAVE SELECTION LIST?
            let addInfo = null;

            if (mdconf[i].SelectionList && mdconf[i].SelectionList.length > 0) {
              addInfo = mdconf[i].SelectionList;
            }

            deviceInfo.values.push({
              name: mdconf[i].Name,
              value: mdconf[i].CurrentValue,
              addInfo: addInfo
            });
          }

          config.push(deviceInfo);
        }
      }

      configuration.push({
        config: config
      });

      // if (initmd.customerModule !== null && initmd.customerModule !== undefined) {
      //   composition.push({
      //     type: initmd.customerModule.Module.Key,
      //     organization: initmd.customerModule.Customer});
      // } else {
      //   composition.push('');
      // }

      // this.sqno++;

      const outputtoname = [];

      for (const outp of initmd.getActiveConnections()) {
        dd.outputs.push({
          outputName: 'support_' + outp.SequenceNumber.toString(),
          sequenceNumber: outp.SequenceNumber.toString()
        });
      }


      return {
        outputName: 'Support',
        connectionPoint: ModuleConnections.support,
        connection: dd
      };

    } else {
      return {
        outputName: 'Support',
        connectionPoint: ModuleConnections.support,
        connection: ''
      };
    }
  }

  public CreateRecipe(project: Project): string {

    const constellation = [];
    const configuration: string[] = [];
    const composition = [];
    const connection = [];

    let configStr = '[';

    this.sqno = 0;

    const initmd = project.Modules.find(ex => ex.isInialModule === true);

    if (initmd !== null && initmd !== undefined) {
      const dd = {
        moduletype: initmd.modul.Type,
        moduleversion: initmd.modul.Version,
        modulename: initmd.modul.Group + ' ' + initmd.modul.Name,
        seqno: this.sqno,
        outputs: []
      };

      let dds = '{' +
        '"moduletype": "' + initmd.modul.Type + '",' +
        '"moduleversion": "' + initmd.modul.Version + '",' +
        '"modulename": "' + initmd.modul.Group + ' ' + initmd.modul.Name + '",' +
        '"seqno": "' + this.sqno + '",' +
        '"outputs": [';


      constellation.push(initmd.rotation);

      // CONFIG

      let obj = '{';

      const moddevs = initmd.modul.Components.filter(ex => ex.Configs.length > 0);

      for (let d = 0; d < moddevs.length; d++) {
        const mdconf = moddevs[d].Configs.filter(ex => (!ex.Mode || ex.Mode !== 'Service') && ex.Excluded === false);

        if (mdconf.length > 0) {
          let vls = '"' + moddevs[d].PlcKey + '": {';

          for (let i = 0; i < mdconf.length; i++) {
            if (!mdconf[i].Virtual) {

              let val = mdconf[i].getValue();

              if (mdconf[i].ShowPositiveOnly === true) {
                val = val * mdconf[i].RecipeDirection;
              }

              vls = vls + '"' + mdconf[i].Name + '": ' + val;
              if (i < mdconf.length - 1) {
                vls = vls + ',';
              }
            }
          }
          vls = vls + '}';
          obj = obj + vls;
          if (d < moddevs.length - 1) {
            obj = obj + ',';
          }
        }
      }
      obj = obj + '}';
      configuration.push(obj);
      configStr += obj + ',';


      if (initmd.modul.NetworkInfo) {
        connection.push({
          node: initmd.modul.NetworkInfo.ipaddress,
          plc: '',
          pose: '',
        });
      } else if (initmd.customerModule) {
        if (initmd.customerModule.NodeIpAddress) {
          connection.push({
            node: initmd.customerModule.NodeIpAddress,
            plc: '',
            pose: '',
          });
        } else {
          connection.push(null);
        }
      } else {
        connection.push(null);
      }

      if (initmd.customerModule !== null && initmd.customerModule !== undefined) {
        if (initmd.customerModule.SerialNumberSetted) {
          composition.push(initmd.customerModule.SerialNumber);
        } else {
          composition.push('');
        }
      } else {
        composition.push('');
      }


      const outputtoname = [];

      let supportSqno = -1;
      let supportModulePlan: SupportModulePlan = null;

      if (project.SupportModules) {
        for (const sup of project.SupportModules) {
          if (sup.haveModuleById(initmd.customerModule.CustomerModuleId)) {
            sup.setModuleSequenceNumber(initmd, this.sqno);

            if (sup.isLastModule(initmd)) {
              this.sqno++;
              supportSqno = this.sqno;
              supportModulePlan = sup;
            }
          }
        }
      }

      this.sqno++;

      for (const outp of initmd.modul.ConveyorBelts) {
        const cp = initmd.connections.find(ex => ex.moduleConnection === outp.ConnectionPoint);
        let connectionName = '';

        if (outp.ConnectionPoint === ModuleConnections.center) {
          connectionName = 'center';
        } else if (outp.ConnectionPoint === ModuleConnections.left) {
          connectionName = 'left';
        } else if (outp.ConnectionPoint === ModuleConnections.right) {
          connectionName = 'right';
        } else if (outp.ConnectionPoint === ModuleConnections.right_3) {
          connectionName = 'right_1';
        } else if (outp.ConnectionPoint === ModuleConnections.left_3) {
          connectionName = 'left_1';
        } else if (outp.ConnectionPoint === ModuleConnections.right_1) {
          const conn = initmd.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.right_3);
          if (conn) {
            connectionName = 'right_2';
          } else {
            connectionName = 'right_1';
          }
        } else if (outp.ConnectionPoint === ModuleConnections.right_2) {
          const conn = initmd.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.right_3);
          if (conn) {
            connectionName = 'right_3';
          } else {
            connectionName = 'right_2';
          }
        } else if (outp.ConnectionPoint === ModuleConnections.left_1) {
          const conn = initmd.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.left_3);
          if (conn) {
            connectionName = 'left_2';
          } else {
            connectionName = 'left_1';
          }
        } else if (outp.ConnectionPoint === ModuleConnections.left_2) {
          const conn = initmd.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.left_3);
          if (conn) {
            connectionName = 'left_3';
          } else {
            connectionName = 'left_2';
          }
        }


        if (cp !== null && cp !== undefined) {
          if (cp.conveyor !== null && cp.conveyor !== undefined) {
            const conv = cp.conveyor;

            if (conv.EndPointType !== EndPointTypes.undefined) {
              if (conv.EndPointType === EndPointTypes.module) {
                const mdl = conv.EndPoint;

                if (mdl !== null && mdl !== undefined) {

                  let uniqe = mdl.CustomerModuleId;


                  const mdpl = project.Modules.find(ex => ex.customerModule.CustomerModuleId === uniqe);

                  if (mdpl !== null && mdpl !== undefined) {
                    outputtoname.push({
                      outputName: outp.OutputName,
                      position: connectionName,
                      module: mdpl,
                      seqno: this.sqno
                    });
                    // this.sqno++;
                  } else {
                    outputtoname.push({
                      outputName: outp.OutputName,
                      position: connectionName,
                      connectionInfo: ''
                    });
                  }

                } else {
                  outputtoname.push({
                    outputName: outp.OutputName,
                    position: connectionName,
                    connectionInfo: ''
                  });
                }

              } else {
                outputtoname.push({
                  outputName: outp.OutputName,
                  position: connectionName,
                  connectionInfo: 'sink'
                });
              }
            } else {
              outputtoname.push({
                outputName: outp.OutputName,
                position: connectionName,
                connectionInfo: ''
              });
            }
          } else {
            outputtoname.push({
              outputName: outp.OutputName,
              position: connectionName,
              connectionInfo: 'notconnected'
            });
          }
        } else {
          outputtoname.push({
            outputName: outp.OutputName,
            position: connectionName,
            connectionInfo: ''
          });
        }
      }


      if (outputtoname.length <= 0 && supportSqno < 0) {
        dds += ' ';
      } else {

        if (supportSqno >= 0) {
          // ADD IT
          dds += '{"' + 'Support' + '":' + this.CreateRecipeForSupportModule(project,
            supportModulePlan,
            constellation,
            configuration,
            composition,
            connection,
            supportSqno,
          ) + '},';

        }

        for (const outp of outputtoname) {
          if (outp.connectionInfo === null || outp.connectionInfo === undefined) {

            // dd.outputs.push(this.CreateRecipeForSubModule(project, outp, constellation, configStr, composition, connection));
            dds += '{"' + outp.position + '":' + this.CreateRecipeForSubModule(project,
              outp,
              constellation,
              configuration, composition,
              connection) + '},';
          } else {
            dd.outputs.push('"' + outp.position + '": "' + outp.connectionInfo + '"');
            dds += '{"' + outp.position + '": "' + outp.connectionInfo + '"},';
          }
        }
      }

      dds = dds.substring(0, dds.length - 1) + ']';

      configStr = '[';

      for (const str of configuration) {
        configStr += str + ',';
      }

      let header = '{';

      header += '"name": "' + project.name + '",' +
        '"description": "",' +
        '"author": "' + project.author + '",' +
        '"created": "' + project.created + '",' +
        '"version": "' + project.version + '"}';


      return '{"header": ' + header +
        ', "tree":' + dds + '}' +
        ', "constellation": ' + JSON.stringify(constellation) +
        ', "configuration": ' + configStr.substring(0, configStr.length - 1) + ']' +
        ', "composition": ' + JSON.stringify(composition) +
        ', "connection": ' + JSON.stringify(connection) +
        '}';
    }

    return '';
  }

  public CreateRecipeForSupportModule(project: Project,
                                      mdl: SupportModulePlan,
                                      constellation: any[],
                                      configuration: string[],
                                      composition: any[],
                                      connection: any[],
                                      supportSeqNo: number): string {

    const initmd: SupportModulePlan = mdl;

    if (initmd !== null && initmd !== undefined) {
      const dd = {
        moduletype: initmd.modul.Type,
        moduleversion: initmd.modul.Version,
        modulename: initmd.modul.Group + ' ' + initmd.modul.Name,
        seqno: supportSeqNo,
        outputs: []
      };

      let dds = '{' +
        '"moduletype": "' + initmd.modul.Type + '",' +
        '"moduleversion": "' + initmd.modul.Version + '",' +
        '"modulename": "' + initmd.modul.Group + ' ' + initmd.modul.Name + '",' +
        '"seqno": "' + supportSeqNo + '",' +
        '"outputs": [';

      constellation.push(initmd.rotation);

      // CONFIG

      let obj = '{';

      const moddevs = initmd.modul.Components.filter(
        ex => ex.Configs.length > 0);

      for (let d = 0; d < moddevs.length; d++) {
        const mdconf = moddevs[d].Configs.filter(ex => (!ex.Mode || ex.Mode !== 'Service') && ex.Excluded === false);

        if (mdconf.length > 0) {
          let vls = '"' + moddevs[d].PlcKey + '": {';
          for (let i = 0; i < mdconf.length; i++) {
            if (!mdconf[i].Virtual) {

              let val = mdconf[i].getValue();

              if (mdconf[i].ShowPositiveOnly === true) {
                val = val * mdconf[i].RecipeDirection;
              }

              vls = vls + '"' + mdconf[i].Name + '": ' + val;

              if (i < mdconf.length - 1) {
                vls = vls + ',';
              }
            }
          }
          vls = vls + '}';
          obj = obj + vls;
          if (d < moddevs.length - 1) {
            obj = obj + ',';
          }
        }
      }
      obj = obj + '}';
      configuration.push(obj);
      // configuration.push(obj);

      if (initmd.modul.NetworkInfo) {
        connection.push({
          node: initmd.modul.NetworkInfo.ipaddress,
          plc: '',
          pose: '',
        });
      } else if (initmd.customerModule) {
        if (initmd.customerModule.NodeIpAddress) {
          connection.push({
            node: initmd.customerModule.NodeIpAddress,
            plc: '',
            pose: '',
          });
        } else {
          connection.push(null);
        }
      } else {
        connection.push(null);
      }

      if (initmd.customerModule !== null && initmd.customerModule !== undefined) {
        if (initmd.customerModule.SerialNumberSetted) {
          composition.push(initmd.customerModule.SerialNumber);
        } else {
          composition.push('');
        }
      } else {
        composition.push('');
      }

      for (const outp of initmd.getActiveConnections()) {
        dd.outputs.push({
          outputName: 'support_' + outp.SequenceNumber.toString(),
          sequenceNumber: outp.SequenceNumber.toString()
        });

        dds += '{"' + 'support_' + outp.SequenceNumber.toString() + '": "' + outp.SequenceNumber.toString() + '"},';
      }

      return dds.substring(0, dds.length - 1) + ']}';

    } else {
      return '""';
    }
  }


  public CreateRecipeForSubModule(project: Project,
                                  mdl: any,
                                  constellation: any[],
                                  configuration: string[],
                                  composition: any[],
                                  connection: any[]): string {

    const initmd: ModulePlan = mdl.module;

    if (initmd !== null && initmd !== undefined) {
      const dd = {
        moduletype: initmd.modul.Type,
        moduleversion: initmd.modul.Version,
        modulename: initmd.modul.Group + ' ' + initmd.modul.Name,
        seqno: mdl.seqno,
        outputs: []
      };

      let dds = '{' +
        '"moduletype": "' + initmd.modul.Type + '",' +
        '"moduleversion": "' + initmd.modul.Version + '",' +
        '"modulename": "' + initmd.modul.Group + ' ' + initmd.modul.Name + '",' +
        '"seqno": "' + this.sqno + '",' +
        '"outputs": [';

      constellation.push(initmd.rotation);

      // CONFIG

      let obj = '{';

      const moddevs = initmd.modul.Components.filter(
        ex => ex.Configs.length > 0);

      for (let d = 0; d < moddevs.length; d++) {
        const mdconf = moddevs[d].Configs.filter(ex => (!ex.Mode || ex.Mode !== 'Service') && ex.Excluded === false);

        if (mdconf.length > 0) {
          let vls = '"' + moddevs[d].PlcKey + '": {';
          for (let i = 0; i < mdconf.length; i++) {
            if (!mdconf[i].Virtual) {

              let val = mdconf[i].getValue();

              if (mdconf[i].ShowPositiveOnly === true) {
                val = val * mdconf[i].RecipeDirection;
              }

              vls = vls + '"' + mdconf[i].Name + '": ' + val;

              if (i < mdconf.length - 1) {
                vls = vls + ',';
              }
            }
          }
          vls = vls + '}';
          obj = obj + vls;
          if (d < moddevs.length - 1) {
            obj = obj + ',';
          }
        }
      }
      obj = obj + '}';
      configuration.push(obj);
      // configuration.push(obj);

      if (initmd.modul.NetworkInfo) {
        connection.push({
          node: initmd.modul.NetworkInfo.ipaddress,
          plc: '',
          pose: '',
        });
      } else if (initmd.customerModule) {
        if (initmd.customerModule.NodeIpAddress) {
          connection.push({
            node: initmd.customerModule.NodeIpAddress,
            plc: '',
            pose: '',
          });
        } else {
          connection.push(null);
        }
      } else {
        connection.push(null);
      }

      if (initmd.customerModule !== null && initmd.customerModule !== undefined) {
        if (initmd.customerModule.SerialNumberSetted) {
          composition.push(initmd.customerModule.SerialNumber);
        } else {
          composition.push('');
        }
      } else {
        composition.push('');
      }


      const outputtoname = [];

      let supportSqno = -1;
      let supportModulePlan: SupportModulePlan = null;

      if (project.SupportModules) {
        for (const sup of project.SupportModules) {
          if (sup.haveModuleById(initmd.customerModule.CustomerModuleId)) {
            sup.setModuleSequenceNumber(initmd, this.sqno);

            if (sup.isLastModule(initmd)) {
              this.sqno++;
              supportSqno = this.sqno;
              supportModulePlan = sup;
            }
          }
        }
      }

      this.sqno++;

      for (const outp of initmd.modul.ConveyorBelts) {
        const cp = initmd.connections.find(ex => ex.moduleConnection === outp.ConnectionPoint);
        let connectionName = '';

        if (outp.ConnectionPoint === ModuleConnections.center) {
          connectionName = 'center';
        } else if (outp.ConnectionPoint === ModuleConnections.left) {
          connectionName = 'left';
        } else if (outp.ConnectionPoint === ModuleConnections.right) {
          connectionName = 'right';
        } else if (outp.ConnectionPoint === ModuleConnections.right_3) {
          connectionName = 'right_1';
        } else if (outp.ConnectionPoint === ModuleConnections.left_3) {
          connectionName = 'left_1';
        } else if (outp.ConnectionPoint === ModuleConnections.right_1) {
          const conn = initmd.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.right_3);
          if (conn) {
            connectionName = 'right_2';
          } else {
            connectionName = 'right_1';
          }
        } else if (outp.ConnectionPoint === ModuleConnections.right_2) {
          const conn = initmd.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.right_3);
          if (conn) {
            connectionName = 'right_3';
          } else {
            connectionName = 'right_2';
          }
        } else if (outp.ConnectionPoint === ModuleConnections.left_1) {
          const conn = initmd.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.left_3);
          if (conn) {
            connectionName = 'left_2';
          } else {
            connectionName = 'left_1';
          }
        } else if (outp.ConnectionPoint === ModuleConnections.left_2) {
          const conn = initmd.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === ModuleConnections.left_3);
          if (conn) {
            connectionName = 'left_3';
          } else {
            connectionName = 'left_2';
          }
        }


        if (cp !== null && cp !== undefined) {


          if (cp.conveyor !== null && cp.conveyor !== undefined) {
            const conv = cp.conveyor;

            if (conv.EndPointType !== EndPointTypes.undefined) {
              if (conv.EndPointType === EndPointTypes.module) {
                const mdls = conv.EndPoint;

                if (mdls !== null && mdls !== undefined) {

                  let uniqe = mdls.CustomerModuleId;

                  const mdpl = project.Modules.find(ex => ex.customerModule.CustomerModuleId === uniqe);

                  if (mdpl !== null && mdpl !== undefined) {
                    outputtoname.push({
                      outputName: outp.OutputName,
                      position: connectionName,
                      module: mdpl,
                      seqno: this.sqno
                    });
                    // this.sqno++;
                  } else {
                    outputtoname.push({
                      outputName: outp.OutputName,
                      position: connectionName,
                      connectionInfo: ''
                    });
                  }

                } else {
                  outputtoname.push({
                    outputName: outp.OutputName,
                    position: connectionName,
                    connectionInfo: ''
                  });
                }

              } else {
                outputtoname.push({
                  outputName: outp.OutputName,
                  position: connectionName,
                  connectionInfo: 'sink'
                });
              }
            } else {
              outputtoname.push({
                outputName: outp.OutputName,
                position: connectionName,
                connectionInfo: ''
              });
            }
          } else {
            outputtoname.push({
              outputName: outp.OutputName,
              position: connectionName,
              connectionInfo: 'notconnected'
            });
          }
        } else {
          outputtoname.push({
            outputName: outp.OutputName,
            position: connectionName,
            connectionInfo: ''
          });
        }
      }
      if (outputtoname.length <= 0 && supportSqno < 0) {
        dds += ' ';
      } else {

        if (supportSqno >= 0) {
          // ADD IT
          // dd.outputs.push(this.CreateRecipeForSupportModule(project,
          //   supportModulePlan,
          //   constellation,
          //   configuration,
          //   composition,
          //   connection,
          //   supportSqno));

          dds += '{"' + 'Support' + '":' +
            this.CreateRecipeForSupportModule(project,
              supportModulePlan,
              constellation,
              configuration,
              composition,
              connection,
              supportSqno) + '},';
        }

        for (const outp of outputtoname) {
          if (outp.connectionInfo === null || outp.connectionInfo === undefined) {


            // dd.outputs.push(this.CreateRecipeForSubModule(project, outp, constellation, configuration, composition, connection));
            dds += '{"' + outp.position + '":' +
              this.CreateRecipeForSubModule(project, outp, constellation, configuration, composition, connection) + '},';
          } else {
            dd.outputs.push('"' + outp.position + '": "' + outp.connectionInfo + '"');
            dds += '{"' + outp.position + '": "' + outp.connectionInfo + '"},';
          }
        }
      }

      return dds.substring(0, dds.length - 1) + ']}';

    } else {
      return '""';
    }
  }

  public DeleteProject() {
    this._activeProject = null;
  }

  LoadRecipieForValidation(project: Project, recipie: string, customer: string, server: ServerCommunicationService): Project {
    if (recipie !== null && recipie !== undefined) {
      const rec = JSON.parse(recipie);
      if (rec.tree !== null) {


        let modules = server.GetCurrentCustomerModules();
        const seqn = rec.tree.seqno;
        const moduleId = rec.tree.moduletype + '-' + rec.tree.moduleversion;

        let moduleElement: ModulePlan;

        if (moduleId !== null && moduleId !== undefined && moduleId !== '') {


          let mdl: CustomerModule;
          mdl = modules.find(ex => ex.Module.Key === moduleId);
          mdl = mdl.Copy();
          mdl.SetId();

          if (mdl !== null && mdl !== undefined) {
            modules = modules.filter(ex => ex.CustomerModuleId !== mdl.CustomerModuleId);
            mdl.Module.SetModuleHandling(this);
            mdl.SerialNumber = null;
            mdl.SetId();
            const pl = new ModulePlan(true, null, mdl);
            pl.seqno = seqn;
            moduleElement = pl;
            project.Modules.push(pl);

          } else {
            if (rec.tree.moduletype !== null && rec.tree.moduletype !== undefined && rec.tree.moduletype !== '') {
              const md = server.GetModuleByModuleType(rec.tree.moduletype + '-' + (rec.tree.moduleversion ? rec.tree.moduleversion : '001'));
              if (md !== null && md !== undefined) {
                const mdn = md.Copy();
                mdn.SetId();
                mdn.SetModuleHandling(this);
                const pl = new ModulePlan(true, mdn, null);
                pl.seqno = seqn;
                moduleElement = pl;
                project.Modules.push(pl);
              }
            }
          }

        } else {
          if (rec.tree.moduletype !== null && rec.tree.moduletype !== undefined && rec.tree.moduletype !== '') {
            const md = server.GetModuleByModuleType(rec.tree.moduletype +
              '-' + (rec.tree.moduleversion ? rec.tree.moduleversion : '001'));
            if (md !== null && md !== undefined) {

              const mdn = md.Copy();
              mdn.SetId();
              mdn.SetModuleHandling(this);
              const pl = new ModulePlan(true, mdn, null);
              pl.seqno = seqn;
              moduleElement = pl;
              project.Modules.push(pl);
            }
          }
        }

        if (rec.tree.outputs.length > 0) {
          for (const output of rec.tree.outputs) {
            if (output.outputName !== null && output.outputName !== undefined && output.outputName !== '') {
              if (output.connectionPoint === ModuleConnections.support) {

                this.GetSupportModuleFromModulePlan(output.connection, project, rec, output, server, modules);

                // LOAD SUPPORT MODULE


              } else {
                const connectionPoint = moduleElement.modul.ConveyorBelts.find(ex => ex.OutputName === output.outputName &&
                  ex.ConnectionPoint === output.connectionPoint);
                if (connectionPoint !== null && connectionPoint !== undefined) {
                  if (moduleElement.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === connectionPoint.ConnectionPoint)) {
                    // OUTPUT TYPES
                    if (output.connection !== '') {
                      if (output.connection === 'notconnected') {
                        // moduleElement.connections.push(new Connections(connectionPoint.ConnectionPoint, null));
                        moduleElement.addConntection(null, connectionPoint.ConnectionPoint);
                      } else if (output.connection === 'sink') {
                        // Get COONVEYOR
                        const allowed = moduleElement.modul.ConveyorBelts.find(
                          ex => ex.ConnectionPoint === connectionPoint.ConnectionPoint);
                        if (allowed !== null && allowed !== undefined) {
                          const conveyor = server.GetConveyorByType(allowed.AllowedConveyorBeltTypes[0]);
                          if (conveyor !== null && conveyor !== undefined) {
                            const conv = conveyor.Copy();
                            conv.SetId(null);
                            conv.setEndPoint(null);
                            if (output.conveyorType) {
                              conv.LenghtType = output.conveyorType;
                            } else {
                              conv.LenghtType = ConveyorLenghtType.long;
                            }
                            // moduleElement.connections.push(new Connections(connectionPoint.ConnectionPoint, conv));
                            moduleElement.addConntection(conv, connectionPoint.ConnectionPoint);
                          }
                        }

                      } else {

                        // Module added to conveyor
                        const mdpl = this.GetModulePlanFromRecipie(output.connection, rec, project, customer, server, modules);
                        if (mdpl) {
                          const allowed = moduleElement.modul.ConveyorBelts.find(
                            ex => ex.ConnectionPoint === connectionPoint.ConnectionPoint);
                          if (allowed !== null && allowed !== undefined) {
                            const conveyor = server.GetConveyorByType(allowed.AllowedConveyorBeltTypes[0]);
                            if (conveyor !== null && conveyor !== undefined) {

                              const conv = conveyor.Copy();
                              conv.SetId(null);
                              if (output.conveyorType) {

                                conv.LenghtType = output.conveyorType;
                              } else {

                                conv.LenghtType = ConveyorLenghtType.long;
                              }
                              if (mdpl.customerModule !== null && mdpl.customerModule !== undefined) {
                                conv.setEndPoint(mdpl.customerModule);
                              } else {
                                conv.setEndPoint(mdpl.modul);
                              }
                              // moduleElement.connections.push(new Connections(connectionPoint.ConnectionPoint, conv));
                              moduleElement.addConntection(conv, connectionPoint.ConnectionPoint);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        if (moduleElement !== null && moduleElement !== undefined) {
          if (rec.constellation != null && rec.constellation !== undefined && rec.constellation.length > seqn) {
            const rot = rec.constellation[seqn];

            if (rot !== null && rot !== undefined) {
              if (rot === 0) {
                moduleElement.rotation = ModuleRotations.degree_0;
              } else if (rot === 90) {
                moduleElement.rotation = ModuleRotations.degree_90;
              } else if (rot === 180) {
                moduleElement.rotation = ModuleRotations.degree_180;
              } else if (rot === 270) {
                moduleElement.rotation = ModuleRotations.degree_270;
              }
            }

          }

          if (rec.configuration != null && rec.configuration !== undefined && rec.configuration.length > seqn) {
            const config = rec.configuration[seqn];


            for (const cv of config.config) {
              const dv = moduleElement.modul.Components.find(ex => ex.PlcKey === cv.name);
              if (dv) {
                for (const val of cv.values) {
                  if (val.addInfo) {
                    const v = dv.Configs.find(ex => ex.Name === val.name);
                    if (v) {
                      v.SelectionList = val.addInfo;
                    }
                  }

                  if (val.value !== null && val.value !== undefined) {
                    const v = dv.Configs.find(ex => ex.Name === val.name);
                    if (v) {
                      v.SetValue(val.value, false);
                    }
                  }
                }
              }
            }
          }
        }

        // CHEK RULES


        // PROJECT LOADED
        return project;


      }
    }
  }


  private CheckRecipeForExistingModules(recipe: any, modules: CustomerModule[]): { module: string, type: number }[] {

    const modulesInRecipe: string[] = [];
    const result: { module: string, type: number }[] = [];

    if (recipe.tree !== null && modules?.length > 0) {
      modulesInRecipe.push(recipe.tree.moduletype + '-' + recipe.tree.moduleversion);
      this.getSubModulesInRecipe(recipe.tree, modulesInRecipe);

      const grouped: { [id: string]: number } = {};

      for (const tp of modulesInRecipe) {
        if (!grouped[tp]) {
          grouped[tp] = 0;
        }
        grouped[tp] = grouped[tp] + 1;
      }

      for (const [key, value] of Object.entries(grouped)) {
        const exists = modules.filter(ex => ex.Module.Key === key);

        if (!exists || exists.length <= 0) {
          result.push({
            module: key,
            type: 0
          });
        } else if (exists.length < value) {
          result.push({
            module: key,
            type: 1
          });
        }
      }
    }

    return result;
  }

  private getSubModulesInRecipe(upperTree: any, result: string[]) {
    if (upperTree.outputs.length > 0) {
      for (const output of upperTree.outputs) {
        if (output.outputName !== null && output.outputName !== undefined && output.outputName !== '') {
          if (output.connection) {
            if (output.connection.moduletype) {
              result.push(output.connection.moduletype + '-' + output.connection.moduleversion);
              this.getSubModulesInRecipe(output.connection, result);
            }
          }
        }
      }
    }
  }

  async LoadRecipie(project: Project, recipie: string, customer: string, server: ServerCommunicationService, emitLoad = true) {
    if (project !== null && project !== undefined && recipie !== null && recipie !== undefined) {
      const rec = JSON.parse(recipie);
      if (rec.tree !== null) {

        let modules = server.GetCurrentCustomerModules();
        const seqn = rec.tree.seqno;

        const moduleCheck: { module: string, type: number }[] = this.CheckRecipeForExistingModules(rec, modules);

        if (moduleCheck.length > 0) {
          const defaulttxt = this.translate.GetTranslation('MESSAGEBOX.HEADERS.ERRORONRECIPELOAD');
          const msg = (moduleCheck[0].type == 0 ? this.translate.GetTranslation('MESSAGEBOX.CONTENT.ERRORONRECIPELOADNOMODULE') : this.translate.GetTranslation('MESSAGEBOX.CONTENT.ERRORONRECIPELOADLESSMODULES')) + moduleCheck[0].module;
          this.dialog.open(ConfirmMessageBoxComponent,
            {panelClass: 'panelclass', data: {header: defaulttxt, content: msg}});
          return;
        }

        if (rec.cluster) {
          project.cluster = rec.cluster;
        }
        const moduleId = rec.tree.moduletype + '-' + rec.tree.moduleversion;

        let moduleElement: ModulePlan;

        if (moduleId !== null && moduleId !== undefined && moduleId !== '') {


          let mdl: CustomerModule;
          mdl = modules.find(ex => ex.Module.Key === moduleId);
          mdl = mdl.Copy();
          mdl.SetId();

          if (mdl !== null && mdl !== undefined) {
            modules = modules.filter(ex => ex.CustomerModuleId !== mdl.CustomerModuleId);
            mdl.Module.SetModuleHandling(this);
            mdl.SerialNumber = null;
            mdl.SetId();

            if (rec.tree.comment) {
              mdl.Comment = rec.tree.comment;
            }

            const pl = new ModulePlan(true, null, mdl);
            pl.seqno = seqn;
            moduleElement = pl;
            project.Modules.push(pl);

          } else {
            if (rec.tree.moduletype !== null && rec.tree.moduletype !== undefined && rec.tree.moduletype !== '') {
              const md = server.GetModuleByModuleType(rec.tree.moduletype + '-' + (rec.tree.moduleversion ? rec.tree.moduleversion : '001'));
              if (md !== null && md !== undefined) {
                const mdn = md.Copy();
                mdn.SetId();
                mdn.SetModuleHandling(this);
                const pl = new ModulePlan(true, mdn, null);
                pl.seqno = seqn;
                moduleElement = pl;
                project.Modules.push(pl);
              }
            }
          }

        } else {
          if (rec.tree.moduletype !== null && rec.tree.moduletype !== undefined && rec.tree.moduletype !== '') {
            const md = server.GetModuleByModuleType(rec.tree.moduletype +
              '-' + (rec.tree.moduleversion ? rec.tree.moduleversion : '001'));
            if (md !== null && md !== undefined) {

              const mdn = md.Copy();
              mdn.SetId();
              mdn.SetModuleHandling(this);
              const pl = new ModulePlan(true, mdn, null);
              pl.seqno = seqn;
              moduleElement = pl;
              project.Modules.push(pl);
            }
          }
        }

        if (rec.tree.outputs.length > 0) {
          for (const output of rec.tree.outputs) {
            if (output.outputName !== null && output.outputName !== undefined && output.outputName !== '') {
              if (output.connectionPoint === ModuleConnections.support) {

                this.GetSupportModuleFromModulePlan(output.connection, project, rec, output, server, modules);

                // LOAD SUPPORT MODULE


              } else {
                const connectionPoint = moduleElement.modul.ConveyorBelts.find(ex => ex.OutputName === output.outputName &&
                  ex.ConnectionPoint === output.connectionPoint);
                if (connectionPoint !== null && connectionPoint !== undefined) {
                  if (moduleElement.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === connectionPoint.ConnectionPoint)) {
                    // OUTPUT TYPES
                    if (output.connection !== '') {
                      if (output.connection === 'notconnected') {
                        // moduleElement.connections.push(new Connections(connectionPoint.ConnectionPoint, null));
                        moduleElement.addConntection(null, connectionPoint.ConnectionPoint);
                      } else if (output.connection === 'sink') {
                        // Get COONVEYOR
                        const allowed = moduleElement.modul.ConveyorBelts.find(
                          ex => ex.ConnectionPoint === connectionPoint.ConnectionPoint);
                        if (allowed !== null && allowed !== undefined) {
                          const conveyor = server.GetConveyorByType(allowed.AllowedConveyorBeltTypes[0]);
                          if (conveyor !== null && conveyor !== undefined) {
                            const conv = conveyor.Copy();
                            conv.SetId(null);
                            conv.setEndPoint(null);
                            if (output.conveyorType) {
                              conv.LenghtType = output.conveyorType;
                            } else {
                              conv.LenghtType = ConveyorLenghtType.long;
                            }
                            // moduleElement.connections.push(new Connections(connectionPoint.ConnectionPoint, conv));
                            moduleElement.addConntection(conv, connectionPoint.ConnectionPoint);
                          }
                        }

                      } else {

                        // Module added to conveyor
                        const mdpl = this.GetModulePlanFromRecipie(output.connection, rec, project, customer, server, modules);
                        if (mdpl) {
                          const allowed = moduleElement.modul.ConveyorBelts.find(
                            ex => ex.ConnectionPoint === connectionPoint.ConnectionPoint);
                          if (allowed !== null && allowed !== undefined) {
                            const conveyor = server.GetConveyorByType(allowed.AllowedConveyorBeltTypes[0]);
                            if (conveyor !== null && conveyor !== undefined) {

                              const conv = conveyor.Copy();
                              conv.SetId(null);
                              if (output.conveyorType) {

                                conv.LenghtType = output.conveyorType;
                              } else {

                                conv.LenghtType = ConveyorLenghtType.long;
                              }
                              if (mdpl.customerModule !== null && mdpl.customerModule !== undefined) {
                                conv.setEndPoint(mdpl.customerModule);
                              } else {
                                conv.setEndPoint(mdpl.modul);
                              }
                              // moduleElement.connections.push(new Connections(connectionPoint.ConnectionPoint, conv));
                              moduleElement.addConntection(conv, connectionPoint.ConnectionPoint);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        if (moduleElement !== null && moduleElement !== undefined) {
          if (rec.constellation != null && rec.constellation !== undefined && rec.constellation.length > seqn) {
            const rot = rec.constellation[seqn];

            if (rot !== null && rot !== undefined) {
              if (rot === 0) {
                moduleElement.rotation = ModuleRotations.degree_0;
              } else if (rot === 90) {
                moduleElement.rotation = ModuleRotations.degree_90;
              } else if (rot === 180) {
                moduleElement.rotation = ModuleRotations.degree_180;
              } else if (rot === 270) {
                moduleElement.rotation = ModuleRotations.degree_270;
              }
            }

          }

          if (rec.configuration != null && rec.configuration !== undefined && rec.configuration.length > seqn) {
            const config = rec.configuration[seqn];


            for (const cv of config.config) {
              const dv = moduleElement.modul.Components.find(ex => ex.PlcKey === cv.name);
              if (dv) {
                for (const val of cv.values) {
                  if (val.addInfo) {
                    const v = dv.Configs.find(ex => ex.Name === val.name);
                    if (v) {
                      v.SelectionList = val.addInfo;
                    }
                  }

                  if (val.value !== null && val.value !== undefined) {
                    const v = dv.Configs.find(ex => ex.Name === val.name);
                    if (v) {
                      v.SetValue(val.value, false);
                    }
                  }
                }
              }
            }
          }
        }

        // CHEK RULES


        // PROJECT LOADED
        if (emitLoad) {
          this.UpdateModuleRules();
          this.sequenceNumberToOnboard = 0;

          this.OnProjectLoaded.emit();
        }


      }
    }
  }

  GetSupportModuleFromModulePlan(device: any,
                                 project: Project,
                                 rec: any,
                                 output: any,
                                 server: ServerCommunicationService,
                                 modules: CustomerModule[]) {

    if (!project.SupportModules) {
      project.SupportModules = [];
    }
    const moduleId = device.moduletype + '-' + device.moduleversion;

    let moduleElement: SupportModulePlan;
    let mdl: CustomerModule;
    mdl = modules.find(ex => ex.Module.Key === moduleId);
    mdl = mdl.Copy();


    if (mdl !== null && mdl !== undefined) {
      modules = modules.filter(ex => ex.CustomerModuleId !== mdl.CustomerModuleId);
      mdl.Module.SetModuleHandling(this);
      mdl.SerialNumber = null;
      mdl.SetId();

      if (rec.tree.comment) {
        mdl.Comment = rec.tree.comment;
      }

      const pl = new SupportModulePlan(true, null, mdl);
      pl.seqno = output.connection.seqno;
      moduleElement = pl;
    } else {
      const mdsup = server.GetModuleByModuleType(output.connection.moduletype +
        '-' + (output.connection.moduleversion ? output.connection.moduleversion : '001'));
      if (mdsup !== null && mdsup !== undefined) {
        const mdn = mdsup.Copy();
        mdn.SetId();
        mdn.SetModuleHandling(this);
        const pl = new SupportModulePlan(true, mdn, null);
        pl.seqno = output.connection.seqno;
        moduleElement = pl;

      }
    }
    // CONNECTIONS
    for (const sout of output.connection.outputs) {
      if (sout.sequenceNumber) {
        const seqnotofind = Number(sout.sequenceNumber);

        const mdlToAdd = project.Modules.find(ex => ex.seqno === seqnotofind);
        if (mdlToAdd) {
          moduleElement.AddSupportNewConnection(mdlToAdd);
        }

      }
    }

    // OTHER INFO
    if (rec.constellation != null && rec.constellation !== undefined && rec.constellation.length > moduleElement.seqno) {
      const rot = rec.constellation[moduleElement.seqno];

      if (rot !== null && rot !== undefined) {
        if (rot === 0) {
          moduleElement.rotation = ModuleRotations.degree_0;
        } else if (rot === 90) {
          moduleElement.rotation = ModuleRotations.degree_90;
        } else if (rot === 180) {
          moduleElement.rotation = ModuleRotations.degree_180;
        } else if (rot === 270) {
          moduleElement.rotation = ModuleRotations.degree_270;
        }
      }

    }

    if (rec.configuration != null && rec.configuration !== undefined && rec.configuration.length > moduleElement.seqno) {
      const config = rec.configuration[moduleElement.seqno];


      for (const cv of config.config) {
        const dv = moduleElement.modul.Components.find(ex => ex.PlcKey === cv.name);
        if (dv) {
          for (const val of cv.values) {
            if (val.addInfo) {
              const v = dv.Configs.find(ex => ex.Name === val.name);
              if (v) {
                v.SelectionList = val.addInfo;
              }
            }

            if (val.value !== null && val.value !== undefined) {
              const v = dv.Configs.find(ex => ex.Name === val.name);
              if (v) {
                v.SetValue(val.value, false);
              }
            }
          }
        }
      }
    }

    project.SupportModules.push(moduleElement);

  }

  GetModulePlanFromRecipie(device: any,
                           rec: any,
                           project: Project,
                           customer: string,
                           server: ServerCommunicationService,
                           modules: CustomerModule[]): ModulePlan {
    if (project !== null && project !== undefined && device !== null && device !== undefined) {
      if (device !== null) {
        const seqn = device.seqno;
        if (rec.constellation.length > seqn) {
          const moduleId = device.moduletype + '-' + device.moduleversion;

          let moduleElement: ModulePlan;

          if (moduleId !== null && moduleId !== undefined && moduleId !== '') {
            // Own module


            let mdl: CustomerModule;
            mdl = modules.find(ex => ex.Module.Key === moduleId);

            if (mdl) {
              mdl = mdl.Copy();
              mdl.SetId();
            } else {

            }

            if (mdl !== null && mdl !== undefined) {

              modules = modules.filter(ex => ex.CustomerModuleId !== mdl.CustomerModuleId);
              mdl.Module.SetModuleHandling(this);
              mdl.SetId();
              mdl.SerialNumber = null;
              const pl = new ModulePlan(false, null, mdl);
              pl.seqno = seqn;
              moduleElement = pl;

              if (device.comment) {
                mdl.Comment = device.comment;
              }

              project.Modules.push(pl);

            } else {
              if (device.moduletype !== null && device.moduletype !== undefined && device.moduletype !== '') {
                const md = server.GetModuleByModuleType(device.moduletype +
                  '-' + (rec.tree.moduleversion ? rec.tree.moduleversion : '001'));
                if (md !== null && md !== undefined) {
                  const mdn = md.Copy();
                  mdn.SetId();
                  const pl = new ModulePlan(false, mdn, null);
                  pl.seqno = seqn;
                  moduleElement = pl;
                  project.Modules.push(pl);
                }
              }
            }

          } else {
            if (device.moduletype !== null && device.moduletype !== undefined && device.moduletype !== '') {
              const md = server.GetModuleByModuleType(device.moduletype + '-' + (rec.tree.moduleversion ? rec.tree.moduleversion : '001'));
              if (md !== null && md !== undefined) {

                const mdn = md.Copy();
                mdn.SetId();
                const pl = new ModulePlan(false, mdn, null);
                pl.seqno = seqn;
                moduleElement = pl;
                project.Modules.push(pl);
              }
            }
          }

          if (device.outputs.length > 0) {
            for (const output of device.outputs) {
              if (output.outputName !== null && output.outputName !== undefined && output.outputName !== '') {

                if (output.connectionPoint === ModuleConnections.support) {

                  // LOAD SUPPORT MODULE
                  this.GetSupportModuleFromModulePlan(output.connection, project, rec, output, server, modules);

                } else {
                  const connectionPoint = moduleElement.modul.ConveyorBelts.find(ex => ex.OutputName === output.outputName &&
                    ex.ConnectionPoint === output.connectionPoint);
                  if (connectionPoint !== null && connectionPoint !== undefined) {
                    if (moduleElement.modul.ConveyorBelts.find(ex => ex.ConnectionPoint === connectionPoint.ConnectionPoint)) {
                      // OUTPUT TYPES
                      if (output.connection !== '') {
                        if (output.connection === 'notconnected') {
                          moduleElement.addConntection(null, connectionPoint.ConnectionPoint);
                        } else if (output.connection === 'sink') {
                          // Get COONVEYOR
                          const allowed = moduleElement.modul.ConveyorBelts.find(
                            ex => ex.ConnectionPoint === connectionPoint.ConnectionPoint);
                          if (allowed !== null && allowed !== undefined) {
                            const conveyor = server.GetConveyorByType(allowed.AllowedConveyorBeltTypes[0]);
                            if (conveyor !== null && conveyor !== undefined) {
                              const conv = conveyor.Copy();
                              conv.SetId(null);
                              if (output.conveyorType) {
                                conv.LenghtType = output.conveyorType;
                              } else {
                                conv.LenghtType = ConveyorLenghtType.long;
                              }
                              conv.setEndPoint(null);
                              moduleElement.addConntection(conv, connectionPoint.ConnectionPoint);
                            }
                          }

                        } else {

                          // Module added to conveyor
                          const mdpl = this.GetModulePlanFromRecipie(output.connection, rec, project, customer, server, modules);
                          if (mdpl) {
                            const allowed = moduleElement.modul.ConveyorBelts.find(
                              ex => ex.ConnectionPoint === connectionPoint.ConnectionPoint);
                            if (allowed !== null && allowed !== undefined) {
                              const conveyor = server.GetConveyorByType(allowed.AllowedConveyorBeltTypes[0]);
                              if (conveyor !== null && conveyor !== undefined) {

                                const conv = conveyor.Copy();
                                conv.SetId(null);
                                if (output.conveyorType) {
                                  conv.LenghtType = output.conveyorType;
                                } else {
                                  conv.LenghtType = ConveyorLenghtType.long;
                                }
                                if (mdpl.customerModule !== null && mdpl.customerModule !== undefined) {
                                  conv.setEndPoint(mdpl.customerModule);
                                } else {
                                  conv.setEndPoint(mdpl.modul);
                                }
                                // moduleElement.connections.push(new Connections(connectionPoint.ConnectionPoint, conv));
                                moduleElement.addConntection(conv, connectionPoint.ConnectionPoint);
                              }
                            }
                          }
                        }
                      }

                    }
                  }
                }
              }
            }
          }

          if (rec.constellation != null && rec.constellation !== undefined && rec.constellation.length > seqn) {
            const rot = rec.constellation[seqn];

            if (rot !== null && rot !== undefined) {
              if (rot === 0) {
                moduleElement.rotation = ModuleRotations.degree_0;
              } else if (rot === 90) {
                moduleElement.rotation = ModuleRotations.degree_90;
              } else if (rot === 180) {
                moduleElement.rotation = ModuleRotations.degree_180;
              } else if (rot === 270) {
                moduleElement.rotation = ModuleRotations.degree_270;
              }
            }

          }

          if (rec.configuration != null && rec.configuration !== undefined && rec.configuration.length > seqn) {
            const config = rec.configuration[seqn];
            for (const cv of config.config) {
              const dv = moduleElement.modul.Components.find(ex => ex.PlcKey === cv.name);
              if (dv) {
                for (const val of cv.values) {
                  if (val.addInfo) {
                    const v = dv.Configs.find(ex => ex.Name === val.name);
                    if (v) {
                      v.SelectionList = val.addInfo;
                    }
                  }
                  if (val.value !== null && val.value !== undefined) {
                    const v = dv.Configs.find(ex => ex.Name === val.name);
                    if (v) {
                      v.SetValue(val.value, false);
                    }
                  }
                }
              }
            }
          }
          return moduleElement;
        }
      }
    }
    return null;
  }

  public SendRecipe() {
    this.OnSendRecipe.emit();
  }

  public ValidateProject(project: Project = this.ActiveProject, checkforUpload = false): any[] {

    const noti: any[] = [];

    if (project) {

      if (project.Modules != null && project.Modules !== null) {
        // Find first module

        const initmd = project.Modules.find(ex => ex.isInialModule === true);

        if (initmd !== null && initmd !== undefined) {
          this.CheckModulePlan(project, initmd, noti, checkforUpload);
        } else {
          noti.push({
            code: ProjectValidationCodes.NO_MODULE,
            text: this.translate.GetTranslation('INFO.RECIPEVALIDATION.NOINITMODULE')
          });
        }

      } else {
        noti.push({
          code: ProjectValidationCodes.NO_MODULE,
          text: this.translate.GetTranslation('INFO.RECIPEVALIDATION.NOMODULES')
        });
      }
    } else {
      noti.push({
        code: ProjectValidationCodes.NO_MODULE,
        text: this.translate.GetTranslation('INFO.RECIPEVALIDATION.NOPROJECT')
      });
    }

    const realNoti: any[] = [];

    for (const entr of noti) {
      if (!realNoti.find(ex => ex.text === entr.text && ex.module === entr.module)) {
        realNoti.push(entr);
      }
    }

    return realNoti;
  }

  public CheckModulePlan(project: Project, moduleplan: ModulePlan, notification: any[], checkForUpload = false) {

    if (moduleplan.customerModule !== null && moduleplan.customerModule !== undefined) {
      if (!moduleplan.modul.HaveConfiguration || checkForUpload === true) {
        moduleplan.modul.HaveConfiguration = true;

        for (const dev of moduleplan.modul.Components) {
          for (const vl of dev.Configs.filter(ex => (!ex.Mode || ex.Mode !== 'Service') && ex.Excluded === false)) {
            let skip = false;

            if (this.CurrentViewMode !== ViewCode.live || checkForUpload === false) {
              if (vl.DesignRelevant === false) {
                skip = true;
              }
            }

            if (!skip) {
              if (vl.CurrentValue !== null && vl.CurrentValue !== undefined) {
                if (vl.DirectionDependency) {
                  if (vl.Direction < 0) {
                    if (vl.CurrentValue < vl.MaxValue) {
                      moduleplan.modul.HaveConfiguration = false;
                      break;
                    }
                    if (vl.CurrentValue > vl.MinValue) {
                      moduleplan.modul.HaveConfiguration = false;
                      break;
                    }
                  } else {
                    if (vl.CurrentValue > vl.MaxValue) {
                      moduleplan.modul.HaveConfiguration = false;
                      break;
                    }
                    if (vl.CurrentValue < vl.MinValue) {
                      moduleplan.modul.HaveConfiguration = false;
                      break;
                    }
                  }
                } else {
                  if (vl.States.length > 0) {
                    if (vl.States.find(ex => ex.State === vl.CurrentValue) !== null) {
                    } else {
                      moduleplan.modul.HaveConfiguration = false;
                      break;
                    }
                  } else {
                    if (vl.CurrentValue > vl.MaxValue) {
                      moduleplan.modul.HaveConfiguration = false;
                      break;
                    }
                    if (vl.CurrentValue < vl.MinValue) {
                      moduleplan.modul.HaveConfiguration = false;
                      break;
                    }
                  }
                }

              } else {
                moduleplan.modul.HaveConfiguration = false;
                break;
              }
            }
          }
        }

      }

      if (!moduleplan.modul.HaveConfiguration) {

        notification.push({
          code: ProjectValidationCodes.NO_CONFIGURATION,
          module: moduleplan.modul.Group + ' ' + moduleplan.modul.Name + ' (' + moduleplan.customerModule.Module.Key + ')',
          text: this.translate.GetTranslation('INFO.RECIPEVALIDATION.INCOMPLETECONFIG')
        });

      }


      var noneConnected = [];

      for (const conpoint of moduleplan.modul.ConveyorBelts) {
        const con = moduleplan.connections.find(ex => ex.moduleConnection === conpoint.ConnectionPoint);

        if (con !== null && con !== undefined) {
          if (con.conveyor !== null && con.conveyor !== undefined) {
            if (con.conveyor.EndPointType !== EndPointTypes.undefined) {
              if (con.conveyor.EndPointType === EndPointTypes.module) {
                const md = con.conveyor.EndPoint;
                if (md !== null && md !== undefined) {
                  const mdpl = project.Modules.find(ex => ex.customerModule.CustomerModuleId === md.CustomerModuleId);
                  if (mdpl !== null && mdpl !== undefined) {
                    this.CheckModulePlan(project, mdpl, notification, checkForUpload);
                  }
                } else {
                  notification.push({
                    code: ProjectValidationCodes.OPEN_CONNECTION,
                    module: moduleplan.modul.Group + ' ' + moduleplan.modul.Name + ' (' + moduleplan.customerModule.Module.Key + ')',
                    text: this.translate.GetTranslation('INFO.RECIPEVALIDATION.NOBELTCONNECTION')
                  });
                }
              }
            } else {
              notification.push({
                code: ProjectValidationCodes.OPEN_CONNECTION,
                module: moduleplan.modul.Group + ' ' + moduleplan.modul.Name + ' (' + moduleplan.customerModule.Module.Key + ')',
                text: this.translate.GetTranslation('INFO.RECIPEVALIDATION.NOBELTCONNECTION')
              });
            }
          } else {
            noneConnected.push(true);
          }
        } else {

          noneConnected.push(true);

          notification.push({
            code: ProjectValidationCodes.OPEN_CONNECTION,
            module: moduleplan.modul.Group + ' ' + moduleplan.modul.Name + ' (' + moduleplan.customerModule.Module.Key + ')',
            text: this.translate.GetTranslation('INFO.RECIPEVALIDATION.NOTCONNECTED')
          });
        }

        if (conpoint.ConnectionPoint === ModuleConnections.center) {
          if (!con) {
            notification.push({
              code: ProjectValidationCodes.OPEN_CONNECTION,
              module: moduleplan.modul.Group + ' ' + moduleplan.modul.Name + ' (' + moduleplan.customerModule.Module.Key + ')',
              text: this.translate.GetTranslation('INFO.RECIPEVALIDATION.NOTCONNECTED')
            });
          } else if (!con.conveyor) {
            notification.push({
              code: ProjectValidationCodes.OPEN_CONNECTION,
              module: moduleplan.modul.Group + ' ' + moduleplan.modul.Name + ' (' + moduleplan.customerModule.Module.Key + ')',
              text: this.translate.GetTranslation('INFO.RECIPEVALIDATION.NOTCONNECTED')
            });
          }
        } else {
          if (conpoint.RelatedInternalBelt) {

            const rulesWithThis = moduleplan.modul.ModuleRules.filter(ex => ex.TriggerComponent === conpoint.RelatedInternalBelt &&
              (ex.Mode === 'Design' || ex.Mode === 'Both'));

            if (rulesWithThis.length > 0) {
              const compx = moduleplan.customerModule.Module.Components.find(ex => ex.PlcKey === conpoint.RelatedInternalBelt);
              if (compx) {
                const params = compx.Configs.find(ex => ex.Name === 'Position');
                if (params) {
                  if (params.CurrentValue === 0) {
                    // ERROR NOT CONNECTED
                    // CECK IF RULE FULLFILED
                    const concrRules = rulesWithThis.filter(ex => ex.TriggerValue.toString() === '0' && ex.TargetValue);

                    let ruleBreak = true;

                    for (const rl of concrRules) {
                      if (rl.TargetComponent && rl.TargetParameter) {
                        // FIND COMP
                        const ruleComp = moduleplan.customerModule.Module.Components.find(ex => ex.PlcKey === rl.TargetComponent);
                        if (ruleComp) {
                          const ruleParam = ruleComp.Configs.find(ex => ex.Name === rl.TargetParameter);
                          if (ruleParam) {
                            if (ruleParam.CurrentValue.toString() === rl.TargetValue) {
                              ruleBreak = false;
                            }
                          }
                        }
                      }
                    }
                    if (ruleBreak) {
                      notification.push({
                        code: ProjectValidationCodes.OPEN_CONNECTION,
                        module: moduleplan.modul.Group + ' ' + moduleplan.modul.Name + ' (' + moduleplan.customerModule.Module.Key + ')',
                        text: this.translate.GetTranslation('INFO.RECIPEVALIDATION.NOTCONNECTED')
                      });
                    }
                  }
                }
              }
            } else {
              // NO RULE
              const compx = moduleplan.customerModule.Module.Components.find(ex => ex.PlcKey === conpoint.RelatedInternalBelt);
              if (compx) {
                const params = compx.Configs.find(ex => ex.Name === 'Position');
                if (params) {
                  if (params.CurrentValue === 0) {
                    // ERROR NOT CONNECTED
                    notification.push({
                      code: ProjectValidationCodes.OPEN_CONNECTION,
                      module: moduleplan.modul.Group + ' ' + moduleplan.modul.Name + ' (' + moduleplan.customerModule.Module.Key + ')',
                      text: this.translate.GetTranslation('INFO.RECIPEVALIDATION.NOTCONNECTED')
                    });
                  }
                }
              }
            }
          }
        }
      }

      if (noneConnected.length === moduleplan.modul.ConveyorBelts.length) {

        notification.push({
          code: ProjectValidationCodes.OPEN_CONNECTION,
          module: moduleplan.modul.Group + ' ' + moduleplan.modul.Name + ' (' + moduleplan.customerModule.Module.Key + ')',
          text: this.translate.GetTranslation('INFO.RECIPEVALIDATION.NOTCONNECTED')
        });
      }


    } else {
      notification.push({
        code: ProjectValidationCodes.NOT_A_CUSTOMER_MODULE,
        module: moduleplan.modul.Group + ' ' + moduleplan.modul.Name,
        text: this.translate.GetTranslation('INFO.RECIPEVALIDATION.NOTOWNMODULE')
      });
    }
  }

  public NetworkOffline() {
    if (this.ActiveProject && this.ActiveProject.Modules && this.ActiveProject.Modules.length > 0) {
      for (const md of this.ActiveProject.Modules) {
        if (md.customerModule) {
          md.setConveyorsOffline();
          md.modul.AddNetworkInfo(null);
          md.customerModule.Module.NetworkInfo = null;
          md.customerModule.Module.setOffline();

          for (const m of md.connections) {
            if (m.conveyor) {
              m.conveyor.setConveyorOnlineState(false);
            }
          }

        }
      }

      if (this.ActiveProject.SupportModules) {
        for (const md of this.ActiveProject.SupportModules) {
          if (md.customerModule) {
            md.modul.AddNetworkInfo(null);
            md.customerModule.Module.NetworkInfo = null;
            md.setConveyorsOffline();
            md.customerModule.Module.setOffline();
          }
        }
      }
    }
  }

  public LoadRecipeOnFinished(rec: any) {
    this.recipieToLoad = rec;
  }

  public UpdateNotifications(newNotifications: any) {

    if (this.ActiveProject && this.ActiveProject.Modules) {
      if (newNotifications.module) {

        const mds = this.ActiveProject.Modules.filter(ex => ex.customerModule);
        if (mds) {
          if (newNotifications.module.serialnumber) {
            const md = mds.find(ex => ex.customerModule.SerialNumber === newNotifications.module.serialnumber);
            if (md) {

              md.customerModule.ActiveWarningNotifications = [];
              md.customerModule.ActiveErrorNotifications = [];

              for (const noti of newNotifications.module.notifications) {
                if (noti.context === 'module') {
                  if (noti.type === 1) { // ERROR
                    // FIND Module:
                    const cmp = md.modul.Components.find(ex => ex.PlcKey === 'Modul' || ex.PlcKey === 'MOD01');
                    if (cmp) {
                      const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Error');  // cmp.Errors.find(ex => ex.Index === noti.code);
                      if (notis) {

                        notis.Component = cmp.PlcKey;
                        notis.ComponentTranslateId = cmp.GetTranslationId();

                        md.customerModule.ActiveErrorNotifications.push(notis);
                      }
                    }
                  } else if (noti.type === 2) { // WARNING
                    // FIND Module:
                    const cmp = md.modul.Components.find(ex => ex.PlcKey === 'Modul' || ex.PlcKey === 'MOD01');
                    if (cmp) {
                      // const notis = cmp.Warnings.find(ex => ex.Index === noti.code);
                      const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Warning');
                      if (notis) {

                        notis.Component = cmp.PlcKey;
                        notis.ComponentTranslateId = cmp.GetTranslationId();


                        md.customerModule.ActiveWarningNotifications.push(notis);
                      }
                    }
                  }
                } else if (noti.context === 'device') {
                  if (noti.type === 1) { // ERROR
                    // FIND Module:


                    const cmp = md.modul.Components.find(ex => ex.PlcKey === noti.ctxId);
                    if (cmp) {


                      if (noti.code !== null && noti.code !== undefined) {
                        // const notis = cmp.Errors.find(ex => ex.Index === noti.code);
                        const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Error');

                        if (notis) {

                          notis.Component = cmp.PlcKey;
                          notis.ComponentTranslateId = cmp.GetTranslationId();

                          md.customerModule.ActiveErrorNotifications.push(notis);
                        }
                      } else if (noti.foreignCode !== null && noti.foreignCode !== undefined) {
                        // const notis = cmp.Errors.find(ex => ex.Index.toString() === noti.foreignCode);
                        const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Error');
                        if (notis) {

                          notis.Component = cmp.PlcKey;
                          notis.ComponentTranslateId = cmp.GetTranslationId();

                          md.customerModule.ActiveErrorNotifications.push(notis);
                        }
                      }


                    }
                  } else if (noti.type === 2) { // WARNING
                    // FIND Module:
                    const cmp = md.modul.Components.find(ex => ex.PlcKey === noti.ctxId);
                    if (cmp) {
                      if (noti.code !== null && noti.code !== undefined) {
                        const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Warning');
                        // const notis = cmp.Warnings.find(ex => ex.Index === noti.code);
                        if (notis) {

                          notis.Component = cmp.PlcKey;
                          notis.ComponentTranslateId = cmp.GetTranslationId();

                          md.customerModule.ActiveWarningNotifications.push(notis);
                        }
                      } else if (noti.foreignCode !== null && noti.foreignCode !== undefined) {
                        // const notis = cmp.Warnings.find(ex => ex.Index.toString() === noti.foreignCode);
                        const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Warning');
                        if (notis) {

                          notis.Component = cmp.PlcKey;
                          notis.ComponentTranslateId = cmp.GetTranslationId();

                          md.customerModule.ActiveWarningNotifications.push(notis);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (this.ActiveProject && this.ActiveProject.SupportModules) {
      if (newNotifications.module) {

        const mds = this.ActiveProject.SupportModules.filter(ex => ex.customerModule);
        if (mds) {
          if (newNotifications.module.serialnumber) {
            const md = mds.find(ex => ex.customerModule.SerialNumber === newNotifications.module.serialnumber);
            if (md) {

              md.customerModule.ActiveWarningNotifications = [];
              md.customerModule.ActiveErrorNotifications = [];

              for (const noti of newNotifications.module.notifications) {
                if (noti.context === 'module') {
                  if (noti.type === 1) { // ERROR
                    // FIND Module:
                    const cmp = md.modul.Components.find(ex => ex.PlcKey === 'Modul' || ex.PlcKey === 'MOD01');
                    if (cmp) {
                      // const notis = cmp.Errors.find(ex => ex.Index === noti.code);
                      const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Error');
                      if (notis) {
                        notis.Component = cmp.PlcKey;
                        notis.ComponentTranslateId = cmp.GetTranslationId();
                        md.customerModule.ActiveErrorNotifications.push(notis);
                      }
                    }
                  } else if (noti.type === 2) { // WARNING
                    // FIND Module:
                    const cmp = md.modul.Components.find(ex => ex.PlcKey === 'Modul' || ex.PlcKey === 'MOD01');
                    if (cmp) {
                      // const notis = cmp.Warnings.find(ex => ex.Index === noti.code);
                      const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Warning');
                      if (notis) {
                        notis.Component = cmp.PlcKey;
                        notis.ComponentTranslateId = cmp.GetTranslationId();
                        md.customerModule.ActiveWarningNotifications.push(notis);
                      }
                    }
                  }
                } else if (noti.context === 'device') {
                  if (noti.type === 1) { // ERROR
                    // FIND Module:


                    const cmp = md.modul.Components.find(ex => ex.PlcKey === noti.ctxId);
                    if (cmp) {
                      if (noti.code !== null && noti.code !== undefined) {
                        const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Error');
                        //const notis = cmp.Errors.find(ex => ex.Index === noti.code);
                        if (notis) {
                          notis.Component = cmp.PlcKey;
                          notis.ComponentTranslateId = cmp.GetTranslationId();
                          md.customerModule.ActiveErrorNotifications.push(notis);
                        }
                      } else if (noti.foreignCode !== null && noti.foreignCode !== undefined) {
                        const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.foreignCode, 'Error');
                        // const notis = cmp.Errors.find(ex => ex.Index.toString() === noti.foreignCode);
                        if (notis) {
                          notis.Component = cmp.PlcKey;
                          notis.ComponentTranslateId = cmp.GetTranslationId();
                          md.customerModule.ActiveErrorNotifications.push(notis);
                        }
                      }


                    }
                  } else if (noti.type === 2) { // WARNING
                    // FIND Module:
                    const cmp = md.modul.Components.find(ex => ex.PlcKey === noti.ctxId);
                    if (cmp) {
                      if (noti.code !== null && noti.code !== undefined) {
                        const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.code, 'Warning');
                        // const notis = cmp.Warnings.find(ex => ex.Index === noti.code);
                        if (notis) {
                          notis.Component = cmp.PlcKey;
                          notis.ComponentTranslateId = cmp.GetTranslationId();
                          md.customerModule.ActiveWarningNotifications.push(notis);
                        }
                      } else if (noti.foreignCode !== null && noti.foreignCode !== undefined) {
                        // const notis = cmp.Warnings.find(ex => ex.Index.toString() === noti.foreignCode);
                        const notis = new NotificationEntry(md.modul.Type + '-' + md.modul.Version, cmp.PlcKey, noti.foreignCode, 'Warning');
                        if (notis) {
                          notis.Component = cmp.PlcKey;
                          notis.ComponentTranslateId = cmp.GetTranslationId();
                          md.customerModule.ActiveWarningNotifications.push(notis);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }

  public LoadOnboardingClusterRecipe(recipe: any, clusterid: string, masterSerial: string, masterIp: string) {
    const project = new Project(recipe.name);
    project.name = recipe.name;
    project.created = recipe.created;
    project.modified = recipe.modified;
    project.public = recipe.public;
    project.author = recipe.author;
    project.id = recipe.id;
    project.valid = recipe.valid;
    project.description = recipe.description;
    if (recipe.tempSaved) {
      project.tempSaved = recipe.tempSaved;
      if (!project.name) {
        project.name = 'temp';
      }
    }
    this.LoadRecipie(project, recipe.recipe, this.usermgmnt.currentUser.CustomerId, this.server, false).then(() => {
      if (this.CurrentViewMode === ViewCode.live) {
        this.ActiveProject = project;
        const sendedRecipe = recipe.recipe;
        if (sendedRecipe) {

          if (sendedRecipe.cluster) {
            project.cluster = sendedRecipe.cluster;
          }

          if (project.cluster) {
            if (project.cluster.modules.length === (this._activeProject.Modules.length +
              (this._activeProject.SupportModules ? this._activeProject.SupportModules.length : 0))) {
              // SET SERIALS
              for (const md of this._activeProject.Modules) {
                if (md.seqno >= 0) {
                  const clusterModule = project.cluster.modules[md.seqno];
                  if (clusterModule) {
                    if (md.seqno >= this.sequenceNumberToOnboard) {
                      this.sequenceNumberToOnboard = md.seqno + 1;
                    }
                    md.customerModule.SerialNumber = clusterModule.serial;
                    md.customerModule.NodeIpAddress = clusterModule.nodeIP;
                    md.customerModule.SerialNumberSetted = true;
                    md.customerModule.Initialized = true;
                    md.customerModule.Module.PositioningRequired = false;
                    md.customerModule.Module.LevelingRequired = false;
                    md.ConfigFinishedSended = true;
                    md.customerModule.Module.ServiceTaskRequired = false;

                    if (md.modul.NetworkInfo) {
                      md.modul.NetworkInfo.levellingrequired = false;
                    }

                    if (md.customerModule.ActiveTasks) {
                      for (const tsk of md.customerModule.ActiveTasks) {
                        tsk.Done = true;
                      }
                    }
                    md.customerModule.FullyConfigured = true;

                  } else {
                    this.ModuleOnboardingRecipeLoaded.emit({
                      success: false,
                      ClusterId: null,
                      MasterSerialNumber: null,
                      recipeId: null,
                      MasterIpAddress: null
                    });
                    return;
                  }

                } else {
                  this.ModuleOnboardingRecipeLoaded.emit({
                    success: false,
                    ClusterId: null,
                    MasterSerialNumber: null,
                    recipeId: null,
                    MasterIpAddress: null
                  });
                  return;
                }
              }

              if (this._activeProject.SupportModules) {
                for (const md of this._activeProject.SupportModules) {
                  if (md.seqno >= 0) {
                    const clusterModule = project.cluster.modules[md.seqno];
                    if (clusterModule) {

                      if (md.seqno >= this.sequenceNumberToOnboard) {
                        this.sequenceNumberToOnboard = md.seqno + 1;
                      }

                      md.customerModule.SerialNumber = clusterModule.serial;
                      md.customerModule.NodeIpAddress = clusterModule.nodeIP;
                      md.customerModule.SerialNumberSetted = true;
                      md.customerModule.Initialized = true;
                      md.customerModule.Module.PositioningRequired = false;
                      md.customerModule.Module.LevelingRequired = false;
                      md.ConfigFinishedSended = true;
                      md.customerModule.Module.ServiceTaskRequired = false;

                      if (md.modul.NetworkInfo) {
                        md.modul.NetworkInfo.levellingrequired = false;
                      }

                      if (md.customerModule.ActiveTasks) {
                        for (const tsk of md.customerModule.ActiveTasks) {
                          tsk.Done = true;
                        }
                      }
                      md.customerModule.FullyConfigured = true;

                    } else {
                      this.ModuleOnboardingRecipeLoaded.emit({
                        success: false,
                        ClusterId: null,
                        MasterSerialNumber: null,
                        recipeId: null,
                        MasterIpAddress: null
                      });
                      return;
                    }

                  } else {
                    this.ModuleOnboardingRecipeLoaded.emit({
                      success: false,
                      ClusterId: null,
                      MasterSerialNumber: null,
                      recipeId: null,
                      MasterIpAddress: null
                    });
                    return;
                  }
                }
              }


              if (project.cluster) {
                this.ModuleOnboardingRecipeLoaded.emit({
                  success: true,
                  ClusterId: project.cluster.clusterId,
                  MasterSerialNumber: project.cluster.masterSerial,
                  recipeId: project.id,
                  MasterIpAddress: project.cluster.masterIp
                });
                return;
              } else {
                this.ModuleOnboardingRecipeLoaded.emit({
                  success: true,
                  ClusterId: clusterid,
                  MasterSerialNumber: masterSerial,
                  recipeId: project.id,
                  MasterIpAddress: masterIp
                });
                return;
              }

            } else {
              this.ModuleOnboardingRecipeLoaded.emit({
                success: false,
                ClusterId: null,
                MasterSerialNumber: null,
                recipeId: null,
                MasterIpAddress: null
              });
            }
          } else {
            this.ModuleOnboardingRecipeLoaded.emit({
              success: false,
              ClusterId: null,
              MasterSerialNumber: null,
              recipeId: null,
              MasterIpAddress: null
            });
          }
        } else {
          this.ModuleOnboardingRecipeLoaded.emit({
            success: false,
            ClusterId: null,
            MasterSerialNumber: null,
            recipeId: null,
            MasterIpAddress: null
          });
        }

      } else {
        this.ModuleOnboardingRecipeLoaded.emit({
          success: false,
          ClusterId: null,
          MasterSerialNumber: null,
          recipeId: null,
          MasterIpAddress: null
        });
        return;
      }
    });
  }

  public CheckIfModuleInitFinished(): boolean {
    let done = true;
    if (!this.modules || this.modules.length <= 0) {
      return false;
    }

    for (const md of this.ActiveProject.Modules) {
      if (!md.customerModule.FullyConfigured) {
        done = false;
      }
    }

    if (this.ActiveProject.SupportModules) {
      for (const md of this.ActiveProject.SupportModules) {
        if (!md.customerModule.FullyConfigured) {
          done = false;
        }
      }
    }

    if (done) {

    }

    return done;
  }

  public saveCurrentProject(name: string, description: string) {
    const data = this.CreateRecipeDatabase(this._activeProject);


    this._activeProject.name = name;
    this._activeProject.description = description;

    // Delete Temp if exists
    if (this._activeProject.tempSaved === true && this._activeProject.id) {
      // DELETE IT
      this.database.DeleteRecipie(this._activeProject.id);
      // this.project.id = null;
    }

    // CLEAR ALL TEMP
    this.database.recipes.toArray().then(recs => {
      const allrcs = recs.filter(ex => ex.name === '' || ex.name === null || ex.name === undefined || ex.name.indexOf('temp') >= 0);
      for (const r of allrcs) {
        this.database.DeleteRecipie(r.id);
      }
    });
    const savevalue = {
      id: this._activeProject.id,
      name: name,
      description: description,
      customer: this.usermgmnt.currentUser.CustomerId,
      author: (this._activeProject.author !== undefined && this._activeProject.author !== null ?
        this._activeProject.author :
        this.usermgmnt.currentUser.Id),
      created: this._activeProject.created,
      public: this._activeProject.public,
      modified: new Date(),
      valid: this.ValidateProject().length <= 0,
      recipe: JSON.stringify(data)
    };

    const returnOfSave = this.database.AddNewRecipe(savevalue);

    returnOfSave.then((result: any) => {

      if (result === false) {
        // ERROR ON SAVE
        this.ProjectSavingFinished.emit(false);
      } else {
        this._activeProject.id = result as string;
        this._activeProject.name = name;
        this._activeProject.description = description;
        this.SetProjectToCookie(this._activeProject);

        this.ProjectSavingFinished.emit(true);

        // this.dialogRef.close(this.project);
      }
    });


  }

  public saveTempProject(essenseialchangereset = false) {

    if (this._activeProject) {
      this._activeProject.changed = false;
      if (!this._activeProject.name) {
        this._activeProject.name = 'temp';
        this._activeProject.tempSaved = true;
      }

      if (this._activeProject.name !== 'temp') {
        // this._activeProject.essentialChange = true;
        return;
      } else {
        this._activeProject.tempSaved = true;
      }

      if (this._activeProject.essentialChange && this._activeProject.essentialChange === true) {
        this._activeProject.cluster = null;

        if (essenseialchangereset) {
          this._activeProject.id = null;
        }
      }

      const savevalue = {
        id: this._activeProject.id,
        name: this._activeProject.name,
        tempSaved: this._activeProject.tempSaved,
        description: this._activeProject.description,
        customer: this.usermgmnt.currentUser.CustomerId,
        author: (this._activeProject.author !== undefined && this._activeProject.author !== null ?
          this._activeProject.author : this.usermgmnt.currentUser.Id),
        created: this._activeProject.created,
        modified: new Date(),
        public: this._activeProject.public,
        valid: this.ValidateProject().length <= 0,
        recipe: JSON.stringify(this.CreateRecipeDatabase(this._activeProject)),
      };

      const returnOfSave = this.database.AddNewRecipe(savevalue);
      if (essenseialchangereset) {
        this._activeProject.essentialChange = false;
      }

      returnOfSave.then((result: any) => {
        if (result === false) {
          // ERROR ON SAVE
        } else {
          if (!this._activeProject.id) {
            this._activeProject.id = result as string;
            // this._activeProject.name = name;
          }
          this.SetProjectToCookie();
        }
      });


    }
  }

  public calculateMasterModule() {

    if (this.modules.length > 0) {

      const numberOfModules = this.modules.length;
      let left = 0.0;
      let top = 0.0;
      for (const mdl of this.modules) {
        left += mdl.correctedLeftNumber;
        top += mdl.correctedTopNumber;
        mdl._modulePlan.geometricMaster = false;
      }

      const centerTop = top / numberOfModules;
      const centerLeft = left / numberOfModules;

      let nearest: ModulePlan = null;
      let minDistance = Number.MAX_VALUE;

      for (const mdl of this.modules) {
        let diffTop = mdl.correctedTopNumber - centerTop;
        let diffLeft = mdl.correctedLeftNumber - centerLeft;

        let distance = Math.sqrt(Math.pow(diffLeft, 2) + Math.pow(diffTop, 2));

        if (distance < minDistance) {
          minDistance = distance;
          nearest = mdl._modulePlan;
        }
      }


      if (nearest) {
        nearest.geometricMaster = true;
      }


    }


  }

  public calculateMasterModuleSecondTry(minLeft: number, maxLeft: number, minTop: number, maxTop: number) {

    if (this.modules.length > 0) {


      const centerTop = ((maxTop - minTop) / 2.0) + minTop;
      const centerLeft = ((maxLeft - minLeft) / 2.0) + minLeft;

      let nearest: ModulePlan = null;
      let minDistance = Number.MAX_VALUE;

      for (const mdl of this.modules) {
        mdl._modulePlan.geometricMaster = false;
        let diffTop = mdl.numericalTop - centerTop;
        let diffLeft = mdl.numericalLeft - centerLeft;

        let distance = Math.sqrt(Math.pow(diffLeft, 2) + Math.pow(diffTop, 2));

        if (distance < minDistance) {
          minDistance = distance;
          nearest = mdl._modulePlan;
        }
      }


      if (nearest) {
        nearest.geometricMaster = true;
      }


    }


  }

  public calculateMasterModuleByHops() {

    if (this.modules.length > 0) {
      for (const mdl of this.modules) {
        mdl._modulePlan.geometricMaster = false;
      }
    }

    if (this.modules.length <= 2) {
      if (this.modules.length > 0) {
        this.modules[0]._modulePlan.geometricMaster = true;
      }
    } else {

      let nearest: ModulePlan = null;
      let minHops = Number.MAX_VALUE;

      let nodes: Nodes[] = [];
      this.getNodes(this.modules.find(ex => ex._modulePlan.isInialModule)._modulePlan, nodes);

      const result: NodesResult[] = [];

      for (const mdls of this.modules.map(ex => ex._modulePlan)) {

        for (const mdlx of this.modules.map(ex => ex._modulePlan)) {

          if (mdls.customerModule.CustomerModuleId != mdlx.customerModule.CustomerModuleId) {
            const hps = this.hops(mdls, mdlx, nodes, 0);

            result.push(new NodesResult(mdls.customerModule.CustomerModuleId, mdlx.customerModule.CustomerModuleId, hps));

          }

        }

      }



      for (const mdls of this.modules.map(ex => ex._modulePlan)) {

        let hps = 0;
        let allEntries = result.filter(ex => ex.fromModule == mdls.customerModule.CustomerModuleId);

        for (const entry of allEntries) {
          hps += entry.hops;
        }

        if (hps < minHops) {
          minHops = hps;
          nearest = mdls;
        }

      }

      if (nearest) {
        nearest.geometricMaster = true;
      }

    }


  }

  public getNodes(module: ModulePlan, nodes: Nodes[]) {
    if (module != null) {
      for (let connection of module.connections) {
        if (connection.conveyor?.EndPointType == EndPointTypes.module) {

          const mdl = this.getModulePlanByCustomerModule(connection.conveyor?.EndPoint as CustomerModule);
          if (mdl) {
            nodes.push(new Nodes(module, mdl));
            this.getNodes(mdl, nodes);
          }
        }
      }
    }
  }

  public getFromNodes(fromModule: ModulePlan, nodes: Nodes[]): Nodes[] {

    let result: Nodes[] = [];
    let nds = nodes.filter(ex => ex.fromModule.customerModule.CustomerModuleId == fromModule.customerModule.CustomerModuleId || ex.toModule.customerModule.CustomerModuleId == fromModule.customerModule.CustomerModuleId);

    for (const n of nds) {
      let nd = n.Copy();

      if (nd.fromModule.customerModule.CustomerModuleId === fromModule.customerModule.CustomerModuleId) {
        nd.target = nd.toModule;
      } else {
        nd.target = nd.fromModule;
      }

      result.push(nd);

    }

    return result;


  }

  public hops(fromModule: ModulePlan, toModule: ModulePlan, nodes: Nodes[], hops: number): number {


    if (nodes.length <= 0) {
      return 0;
    }

    let nds = this.getFromNodes(fromModule, nodes);

    if (nds.length <= 0) {
      return 0;
    } else {
      for (const nd of nds) {
        if (nd.target.customerModule.CustomerModuleId === toModule.customerModule.CustomerModuleId) {
          return hops + 1;
        } else {
          let nm = this.hops(nd.target, toModule, nodes.filter(ex => ex.id != nd.id), hops + 1);

          if (nm > 0) {
            return nm;
          }

        }
      }
    }

  }


  public getModulePlanByCustomerModule(customerModule: CustomerModule): ModulePlan {
    if (customerModule != null) {
      return this._activeProject.Modules.find(ex => ex.customerModule.CustomerModuleId === customerModule.CustomerModuleId);
    }
    return null;
  }


}

class Nodes {

  id: string;

  fromModule: ModulePlan;
  toModule: ModulePlan;

  target: ModulePlan;

  constructor(from: ModulePlan, to: ModulePlan) {
    this.id = uuidV4.v4();
    this.fromModule = from;
    this.toModule = to;
  }

  public Copy(): Nodes {
    const nd = new Nodes(this.fromModule, this.toModule);
    nd.id = this.id;
    return nd;
  }

}

class NodesResult {
  fromModule: string;
  toModule: string;

  hops: number;

  constructor(from: string, to: string, hops: number) {
    this.fromModule = from;
    this.toModule = to;
    this.hops = hops;
  }


}

import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {ComponentVariable} from '../../../classes/component-variable';
import {ModuleHandlingService} from '../../../services/module-handling.service';
import {VariableStates} from '../../../classes/variable-states';
import {BluectrlTranslateService} from '../../../services/bluectrl-translate.service';


@Component({
  selector: "app-module-device-configurator",
  templateUrl: "./component-configurator.component.html",
  styleUrls: ["./component-configurator.component.css"],
})
export class ComponentConfiguratorComponent implements OnInit {
  @Input() deviceValue: ComponentVariable;
  @Output() OnValueChanged = new EventEmitter<ComponentVariable>();
  @ViewChild("inputCurrentValue", { static: true }) inputData: ElementRef;

  private holdInterval: any;
  private holdStartTime: number;
  private readonly initialDelay = 350; // Initial delay before starting continuous changes
  private readonly minInterval = 1; // Minimum interval between changes (max speed)
  private readonly maxInterval = 100; // Maximum interval between changes (initial speed)
  private readonly accelerationFactor = 0.7; // Factor to decrease interval (speed up faster)

  constructor(
    public moduleHandling: ModuleHandlingService,
    public translate: BluectrlTranslateService
  ) {}

  private startHoldOperation(operation: 'add' | 'sub') {
    this.holdStartTime = Date.now();
    this.holdInterval = setInterval(() => {
      // Calculate how long the button has been held
      const holdDuration = Date.now() - this.holdStartTime;
      
      // Only start after initial delay
      if (holdDuration > this.initialDelay) {
        // Calculate current interval based on hold duration
        const currentInterval = Math.max(
          this.minInterval,
          this.maxInterval * Math.pow(this.accelerationFactor, Math.floor(holdDuration / 100))
        );
        
        // Clear current interval and set new one if speed should change
        if (this.holdInterval && currentInterval !== this.maxInterval) {
          clearInterval(this.holdInterval);
          this.holdInterval = setInterval(() => {
            operation === 'add' ? this.AddValue() : this.SubValue();
          }, currentInterval);
        }
        
        // Execute the operation
        operation === 'add' ? this.AddValue() : this.SubValue();
      }
    }, this.maxInterval);
  }

  private stopHoldOperation() {
    if (this.holdInterval) {
      clearInterval(this.holdInterval);
      this.holdInterval = null;
    }
    this.holdStartTime = null;
  }

  public onAddStart(event: MouseEvent | TouchEvent) {
    event.preventDefault(); // Prevent context menu
    this.AddValue(); // Immediate first click
    this.startHoldOperation('add');
  }

  public onAddEnd(event: MouseEvent | TouchEvent) {
    event.preventDefault();
    this.stopHoldOperation();
  }

  public onSubStart(event: MouseEvent | TouchEvent) {
    event.preventDefault(); // Prevent context menu
    this.SubValue(); // Immediate first click
    this.startHoldOperation('sub');
  }

  public onSubEnd(event: MouseEvent | TouchEvent) {
    event.preventDefault();
    this.stopHoldOperation();
  }

  ngOnInit() {
    if (this.deviceValue.FeatureName === "ConfigurableDualPosition") {
    }
  }

  public ValueChanged($event) {
    this.deviceValue.CurrentValue = $event;

    this.OnValueChanged.emit(this.deviceValue);
  }

  public ParameterDisabled(): boolean {
    if (this.deviceValue.Name === "ServiceActive") {
      return true;
    }

    if (
      this.deviceValue.Deactivated &&
      this.deviceValue.Deactivated.length > 0
    ) {
      const vl = this.deviceValue.Deactivated.find(
        (ex) => ex === this.moduleHandling.CurrentViewMode
      );

      return !!vl;
    }
    return false;
  }

  Forward() {
    if (this.deviceValue.DirectionDependency) {
      this.deviceValue.Direction = 1;
      this.deviceValue.MaxValue = Math.abs(this.deviceValue.MaxValue);
      this.deviceValue.MinValue = Math.abs(this.deviceValue.MinValue);
      if (this.deviceValue.CurrentValue) {
        this.deviceValue.CurrentValue = Math.abs(this.deviceValue.CurrentValue);
      }
    }
  }

  Backward() {
    if (this.deviceValue.DirectionDependency) {
      this.deviceValue.Direction = -1;
      this.deviceValue.MaxValue = this.deviceValue.Direction * Math.abs(this.deviceValue.MaxValue);
      this.deviceValue.MinValue = this.deviceValue.Direction * Math.abs(this.deviceValue.MinValue);
      if (this.deviceValue.CurrentValue) {
        this.deviceValue.CurrentValue = this.deviceValue.Direction * Math.abs(this.deviceValue.CurrentValue);
      }
    }
  }

  OnInputChange(event: any) {
    this.deviceValue.CurrentValue = event.value;
  }

  AddValue() {
    // check if null
    if (!this.deviceValue.CurrentValue) {
      this.deviceValue.CurrentValue = 0;
    }

    // eg. 123->124 or 1440 -> 1450 or 15500 -> 15600
    let stepFactor = 1;
    let calcValue = this.deviceValue.MaxValue;
    while (calcValue > 999 && this.deviceValue.CurrentValue % 10 == 0) {
      calcValue = calcValue / 10;
      stepFactor = stepFactor * 10;
    }
    this.deviceValue.SetValue(
      this.deviceValue.CurrentValue + this.deviceValue.StepSize * stepFactor
    );

    // eg 1327 -> 1330, 1.53->1.54
    stepFactor =
    stepFactor == 1 && this.deviceValue.CurrentValue > 999
      ? stepFactor * 10
      : stepFactor;
    if (this.deviceValue.CurrentValue % stepFactor != 0 && this.deviceValue.CurrentValue % 1 == 0) {
      this.deviceValue.SetValue(
        this.deviceValue.CurrentValue +
          (stepFactor - (this.deviceValue.CurrentValue % stepFactor))
      );
    }

    // check out of bounds
    if (this.deviceValue.CurrentValue < this.deviceValue.MinValue) {
      this.deviceValue.SetValue(this.deviceValue.MinValue);
    }
    if (this.deviceValue.CurrentValue > this.deviceValue.MaxValue) {
      this.deviceValue.SetValue(this.deviceValue.MaxValue);
    }

    const stl = 1.0 / this.deviceValue.StepSize;

    this.deviceValue.SetValue(
      Math.round(this.deviceValue.CurrentValue * stl) / stl
    );

    this.OnValueChanged.emit(this.deviceValue);
  }

  SubValue() {
    // check if null
    if (!this.deviceValue.CurrentValue) {
      this.deviceValue.CurrentValue = 0;
    }

    // eg. 124->123 or 1450 -> 1440 or 15600 -> 15500
    let stepFactor = 1;
    let calcValue = this.deviceValue.MaxValue;
    while (calcValue > 999 && this.deviceValue.CurrentValue % 10 == 0) {
      calcValue = calcValue / 10;
      stepFactor = stepFactor * 10;
    }
    this.deviceValue.SetValue(
      this.deviceValue.CurrentValue - this.deviceValue.StepSize * stepFactor
    );

    // eg 1327 -> 1320, 1.53->1.52
    stepFactor =
      stepFactor == 1 && this.deviceValue.CurrentValue > 999
        ? stepFactor * 10
        : stepFactor;
    if (this.deviceValue.CurrentValue % stepFactor != 0 && this.deviceValue.CurrentValue % 1 == 0) {
      this.deviceValue.SetValue(this.deviceValue.CurrentValue - (this.deviceValue.CurrentValue % stepFactor));
    }

    // check out of bounds
    if (this.deviceValue.CurrentValue < this.deviceValue.MinValue) {
      this.deviceValue.SetValue(this.deviceValue.MinValue);
    }
    if (this.deviceValue.CurrentValue > this.deviceValue.MaxValue) {
      this.deviceValue.SetValue(this.deviceValue.MaxValue);
    }

    const stl = 1.0 / this.deviceValue.StepSize;

    this.deviceValue.SetValue(
      Math.round(this.deviceValue.CurrentValue * stl) / stl
    );

    this.OnValueChanged.emit(this.deviceValue);
  }

  public GetTranslationOfState(state: any) {
    if (this.deviceValue.ValuePath && this.deviceValue.DisplayPath) {
      for (const s of this.deviceValue.SelectionList) {
        if (s[this.deviceValue.ValuePath] == state) {
          // this.ValueChangedInSelector(s[this.deviceValue.ValuePath]);
          // console.log('its a state');
          // console.log(s[this.deviceValue.DisplayPath]);

          return s[this.deviceValue.DisplayPath];
        }
      }
    }

    const st = this.deviceValue.States.find((ex) => ex.State === state);

    if (st) {
      return this.GetStateTranslation(st);
    } else {
      return "NOT FOUND";
    }
  }

  public GetStateTranslation(state: VariableStates) {
    if (state) {
      const translated = this.translate.GetTranslation(
        state.GetTranslationID()
      );

      if (translated !== state.GetTranslationID()) {
        return translated;
      } else {
        return state.GetName();
      }
    } else {
      return "";
    }
  }

  public GetCurrentValue(): any {
    if (this.deviceValue.States.length > 0) {
      if (this.deviceValue.CurrentValue || this.deviceValue.CurrentValue === 0 || this.deviceValue.CurrentValue === false) {
        const fnd = this.deviceValue.States.find((ex) => ex.State === this.deviceValue.CurrentValue);
        if (fnd) {
          const translated = this.translate.GetTranslation(fnd.GetTranslationID());
          if (translated !== fnd.GetTranslationID()) {
            return translated;
          } else {
            return fnd.GetName();
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return this.deviceValue.CurrentValue;
    }
  }

  attributeDisplay(attribute1, attribute2) {

    if (attribute1 == attribute2) {
      return attribute1;
    } else if (this.deviceValue.getValue() === attribute1) {
      return attribute1;
    } else {
      return "";
    }
  }

  public ValueChangedInSelector($event) {
    if ($event === "true") {
      this.deviceValue.CurrentValue = true;
    } else if ($event === "false") {
      this.deviceValue.CurrentValue = false;
    } else if (!isNaN(Number($event))) {
      this.deviceValue.CurrentValue = Number($event);
    } else {
      this.deviceValue.CurrentValue = $event;
    }

    // if (this.deviceValue.Virtual) {
    this.deviceValue.forceValueChanged();
    // }
    this.OnValueChanged.emit(this.deviceValue);
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {Dexie} from 'dexie';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';
import * as Sentry from "@sentry/angular";
import { title } from 'process';

// Initialize Sentry - Sentry is a tool that helps you monitor and fix crashes in real time.
Sentry.init({
  dsn: "https://8ca1c005a2ebdac25a0e812825d2a267@o4507270098845696.ingest.de.sentry.io/4508001237205072",
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        "https://api.bluectrl.at/",
        "https://stageing.api.bluectrl.at/",
        "https://demo.api.bluectrl.at/",
      ],
      networkRequestHeaders: ["X-Custom-Header"],
      networkResponseHeaders: ["X-Custom-Header"],
    }),
    Sentry.feedbackIntegration({
      colorScheme: "system",
      autoInject: false, // remove default button as it would overlap with other buttons
    }),
  ],
  //make sure user generated errors are not grouped together
  beforeSend(event, hint) {
    if (
      event.exception?.values[0]?.value.includes(
        "Manual error for report dialog"
      )
    ) {
      event.fingerprint = [event.event_id];
    }

    return event;
  },
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  tracePropagationTargets: [
    "localhost:4200", // Your Angular app
    /^https:\/\/bluectrl\.at/,
    /^https:\/\/dev\.bluectrl\.at/,
    /^https:\/\/stage\.bluectrl\.at/,
    /^https:\/\/demo\.bluectrl\.at/,
    /^https:\/\/v2\.bluectrl\.at/,
    /^https:\/\/bluectrl\.app/,
    /^https:\/\/demo\.bluectrl\.app/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // Percentage of normal sessions that are recorded
  replaysOnErrorSampleRate: 1.0, // Percentage of sessions with errors that are recorded
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

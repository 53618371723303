<section class="flex-col stretch-col">
  <div class="header flex-row space-between">
    <h2 class="uppercaseText center-self" mat-dialog-title>{{'DIALOGS.SAVERECIPE.TITLE' | i18next }}</h2>
    <div class = "flex-1 justify-end center-self">
      <button mat-button (click)="CloseDialog()">
         <img [ngClass]="{'invertedWhite': moduleHandling.CurrentViewMode !== 1, 'invertedBlack': moduleHandling.CurrentViewMode === 1}" src="/assets/general/close.svg">
      </button>
    </div>
  </div>

  <div class="inputs flex-col stretch-col center-self">
    <mat-dialog-content [formGroup]="form" class="flex-col stretch-col gap-10">
      <mat-form-field *ngIf="moduleHandling.CurrentViewMode === 0" class="inputblue flex-col">
        <input matInput placeholder="{{'DIALOGS.SAVERECIPE.PROJECTNAME' | i18next }}" formControlName="ProjectName">
      </mat-form-field>
      <div class="errorinfo" *ngIf="errorText">
        {{errorText}}
      </div>
      <mat-form-field *ngIf="moduleHandling.CurrentViewMode === 0" class="inputblue flex-col">
        <textarea matInput placeholder="{{'DIALOGS.SAVERECIPE.DESCRIPTION' | i18next }}" formControlName="Description"></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="moduleHandling.CurrentViewMode === 1" class="inputgray flex-col">
        <input matInput placeholder="{{'DIALOGS.SAVERECIPE.PROJECTNAME' | i18next }}" formControlName="ProjectName">
      </mat-form-field>
      <mat-form-field *ngIf="moduleHandling.CurrentViewMode === 1" class="inputgray flex-col">
        <textarea matInput placeholder="{{'DIALOGS.SAVERECIPE.DESCRIPTION' | i18next }}" formControlName="Description"></textarea>
      </mat-form-field>
    </mat-dialog-content>
  </div>

  <div class="infos flex-col" *ngIf="moduleHandling.ValidateProject().length > 0">
    <div class="moduleheader flex-row">
      <p>{{'DIALOGS.SAVERECIPE.VALIDATION' | i18next }} ( {{moduleHandling.ValidateProject().length}} ) </p>
      <svg class="updownicon" *ngIf="!OwnVisible" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 32 32" (click)="OwnVisible = true">
        <g>
          <rect height="32" width="32" fill="none" />
          <path id="path1" transform="rotate(0,16,16) translate(0,6.1274995803833) scale(1,1)  " d="M0,0L32,0 16,19.745z" />
        </g>
      </svg>
      <svg class="updownicon" *ngIf="OwnVisible" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 32 32" (click)="OwnVisible = false">
        <g>
          <rect height="32" width="32" fill="none" />
          <path id="path1" transform="rotate(180,16,16) translate(0,6.1274995803833) scale(1,1)  " d="M0,0L32,0 16,19.745z" />
        </g>
      </svg>
    </div>
    <div class="tablediv" *ngIf="OwnVisible">
      <table class="infotable flex-col" *ngFor="let inf of moduleHandling.ValidateProject()">
        <tr  class="elementRow flex-row stretch-row">
          <td style="width: 35%" class="elementText"><p class="contentp">{{inf.module}}</p></td>
          <td style="width: 65%" class="elementText"><p class="contentp">{{inf.text}}</p></td>
        </tr>
      </table>
    </div>
  </div>

  <div class="savediv flex-row center-self">
    <button mat-button (click)="SaveProject()">
       <img [ngClass]="{'invertedWhite': moduleHandling.CurrentViewMode !== 1, 'invertedBlack': moduleHandling.CurrentViewMode === 1}" src="/assets/general/save.svg">
    </button>
  </div>
</section>
